import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Team/style.css";
import partners from "../images/Partners.png";
const Partners = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <section class="main-head">
                <ul class="nav red-4 navbar-nav">
                    <li
                        style={{
                            position: "absolute",
                            right: "50px",
                            top: "-34px",
                        }}
                    >
                        <div class="dropdown">
                            <span>Arabic</span>

                            <div class="dropdown-content">
                                <p>English</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <div
                    class="main-menu-wrapper"
                    style={{ position: "absolute", left: "371px" }}
                >
                    <ul class="c-menu-main-menu" style={{ zIndex: 999 }}>
                        <li>
                            <a href="#" class="link">
                                <div class="link-before">
                                    <img
                                        width="54"
                                        height="61"
                                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                                    ></img>
                                </div>
                                <Link to="/">
                                    <span>Home</span>
                                </Link>
                            </a>
                        </li>
                        <li>
                            <Link to="/objectivesandstrategies" class="link">
                                <div class="link-before">
                                    <img
                                        width="54"
                                        height="61"
                                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                                    ></img>
                                </div>
                                <span style={{ width: "262px" }}>
                                    Our Strategies And Objectives
                                </span>
                            </Link>
                            <img
                                class="tQfdq"
                                style={{
                                    top: "-2px",
                                    position: "absolute",
                                    zIndex: 10,
                                    left: "184px",
                                    height: "70px",
                                }}
                                width="54"
                                height="61"
                                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
                            ></img>
                        </li>
                    </ul>
                </div>
                <h1>
                    <div
                        id="partners_title"
                        class="field_title_header"
                        style={{
                            position: "absolute",
                            top: "196px",
                            zIndex: 1,
                            right: "590px",
                            fontSize: "48px",
                            fontWeight: 700,
                            fontFamily: "Philosopher",
                            color: "#fff",
                        }}
                    >
                        PARTNERS
                    </div>
                </h1>
                <div
                    id="partners-hero-header"
                    class="hero-header"
                    style={{
                        backgroundImage: "url(/assets/images/partners.jpg)",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        backgroundPosition: "center",
                    }}
                >
                    <div
                        class="tQfdq"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "70px",
                            padding: "37px 51px",
                            position: "absolute",
                            backgroundColor: "#fff",
                            top: "-38px",
                            left: "-72px",
                            borderBottomRightRadius: "55px",
                            zIndex: 99,
                        }}
                    >
                        <img
                            src="/assets/images/mmh-logo.png"
                            alt="logo"
                            class="logo-web"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                alignItems: "center",
                                height: "60px",
                            }}
                        />
                    </div>
                    <img
                        width="43"
                        height="43"
                        class="tQfdq"
                        style={{
                            position: "absolute",
                            top: "94px",
                            zIndex: "3",
                            left: "-4px",
                        }}
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0M3B4IiB2aWV3Qm94PSIwIDAgNDMgNDMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCA3QDN4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFjY3VlaWwtLS1QYXJ0aWN1bGllcnMtMDEtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0My4wMDAwMDAsIC0yMDcuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00NDMsMjcxIEw0NDMsMjUwLjExOTE4OCBDNDQzLDIyNi4zNzA5NDQgNDYyLjI1MTc1NiwyMDcuMTE5MTg4IDQ4NiwyMDcuMTE5MTg4IEw0ODYsMjA3LjExOTE4OCBMNDg2LDIwNy4xMTkxODggTDQ0MywyMDcuMTkwNDMgTDQ0MywyNzEgWiIgaWQ9IlBhdGgtNyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
                    ></img>
                    <img
                        class="tQfdq"
                        style={{
                            position: "absolute",
                            zIndex: 10,
                            top: "-53.7px",
                            left: "267px",
                            height: "151px",
                        }}
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTlweCIgaGVpZ2h0PSIxNDFweCIgdmlld0JveD0iMCAwIDk5IDE0MSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTggKDg0NjYzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoIDcgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNC1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAyLjAwMDAwMCwgMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMwMiwxNDAuMTE5MTg4IEwzMDIuMjAyNDY0LDE0MC4xMTkxODggQzMyMi4yOTkxMzUsMTQwLjExOTE4OCAzNDAuNjY3MDU1LDEyOC43NTI3MjYgMzQ5LjYzNDA3NiwxMTAuNzY3NDkgTDM2OS43MzczNDUsNzAuNDQ2MTgyMyBDMzc1LjY0NzYsNTguNTkxOTMxNiAzODcuNzU0MDgxLDUxLjEwMDE4MTMgNDAxLDUxLjEwMDE4MTMgTDQwMSw1MS4xMDAxODEzIEw0MDEsNTEuMTAwMTgxMyBMNDAxLDAgTDMwMS45OTM1NzEsMCIgaWQ9IlBhdGgtNy1Db3B5Ij48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
                    />
                    {/* <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-lg-12 z-1  simple ">
                                <h1>
                                    <div className="field_title_header">
                                        Our Objectives & Strategies
                                    </div>
                                </h1>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "20px",
                }}
            >
                <img
                    src={partners}
                    alt="partners"
                    width="120px"
                    height="123px"
                />
                {/* <h2
                    class="Question"
                    style={{
                        fontFamily: "philosopher",
                        marginTop: "20px",
                        fontSize: "25px",
                    }}
                >
                    Who are the partners of our company ?
                </h2> */}
            </div>
            <div
                class="partners-Banner"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "50px 0",
                }}
            >
                {/* <img src="/assets/images/MakkahMadina.png" alt="makkahMadina" />
                <img src="/assets/images/goldBar.png" alt="GoldBar" /> */}
                <h2>Under Construction</h2>
            </div>
            <div class="filters">
                <div class="b-filter">
                    <Link to="/FAQ">
                        <img
                            src="/assets/Block/FAQ.png"
                            alt="Team"
                            class="icon"
                            style={{
                                borderRadius: "50%",
                                height: "77px",
                                width: "105px",
                                transform: "translateY(-14px)",
                            }}
                        />
                        <span
                            class="name"
                            style={{
                                transform: "translateY(-10px)",
                            }}
                        >
                            FAQ
                        </span>
                    </Link>
                </div>

                <div class="b-filter">
                    <Link to="/digitalTransformation">
                        <img
                            src="/assets/Block/Digitalisation.png"
                            alt="Digital transformation"
                            class="icon"
                            style={{ borderRadius: "50%" }}
                        />
                        <span class="name">
                            Digital Transformation in Oil And Gas
                        </span>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Partners;
