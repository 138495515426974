import "./WhatWeDo.css";
import { useEffect } from "react";
import WOW from "wowjs";

const WhatWeDo = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <div id="container" style={{ marginTop: "30px", marginBottom: "80px" }}>
      <h2
        id="title"
        style={{ fontWeight: "400" }}
        className="wow fadeInLeft"
        data-wow-delay="0.3s"
      >
        What We Do ?
      </h2>
      <div id="cards">
        <div class="card wow fadeInLeft" data-wow-delay="0.3s">
          <img
            className="cover"
            style={{ width: "100%", height: "185px" }}
            src={require("./images/pexels-fauxels-3183186.jpg")}
            alt=""
          />
          <div className="text">
            <h4>Consulting & Project Management</h4>
          </div>
        </div>
        <div className="card wow fadeInLeft" data-wow-delay="0.4s">
          <img
            className="cover"
            style={{ width: "100%", height: "184px" }}
            src={require("./images/pexels-anna-nekrashevich-6802042.jpg")}
            alt=""
          />
          <div className="text">
            <h4>Direct & Third-Party Investement</h4>
          </div>
        </div>
        <div className="card wow fadeInLeft" data-wow-delay="0.5s">
          <img
            className="cover"
            style={{ width: "100%", height: "183px" }}
            src={require("./images/oil&gas.png")}
            alt=""
          />
          <div className="text">
            <h4>Nonrenewable & renewable energy</h4>
          </div>
        </div>
        <div className="card wow fadeInLeft" data-wow-delay="0.6s">
          <img
            className="cover"
            src={require("./images/oil-and-gas-logistics.jpg")}
            alt=""
            style={{ width: "100%", height: "183px" }}
          />
          <div className="text">
            <h4>Fuel Supply & Logistics</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
