import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import "./style.css";
import coverTeam from "../../images/coverTeam.png";
import management from "./management.png";

const RonDetails = () => {
    SwiperCore.use([Autoplay]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section class="main-head">
                <div
                    class="main-menu-wrapper"
                    id="mobile-main-menu-wrapper"
                    style={{ position: "absolute", left: "371px" }}
                >
                    <ul class="c-menu-main-menu" style={{ zIndex: 999 }}>
                        <li>
                            <Link to="/" class="link">
                                <div class="link-before">
                                    <img
                                        width="54"
                                        height="61"
                                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                                    ></img>
                                </div>

                                <span>Home</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/objectivesandstrategies" class="link">
                                <div class="link-before">
                                    <img
                                        width="54"
                                        height="61"
                                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                                    ></img>
                                </div>
                                <span style={{ width: "262px" }}>
                                    Our Strategies And Objectives
                                </span>
                            </Link>
                            <img
                                class="tQfdq"
                                style={{
                                    top: "-2px",
                                    position: "absolute",
                                    zIndex: 10,
                                    left: "184px",
                                    height: "70px",
                                }}
                                width="54"
                                height="61"
                                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
                            ></img>
                        </li>
                    </ul>
                </div>
                <ul class="nav red-4 navbar-nav">
                    <li
                        style={{
                            position: "absolute",
                            right: "50px",
                            top: "-34px",
                        }}
                    >
                        <div class="dropdown">
                            <span>Arabic</span>

                            <div class="dropdown-content">
                                <p>English</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <h1>
                    <div
                        class="field_title_header"
                        style={{
                            position: "absolute",
                            top: "196px",
                            zIndex: 1,
                            right: "539px",
                            fontSize: "48px",
                            fontWeight: 700,
                            fontFamily: "Philosopher",
                            color: "#fff",
                        }}
                    >
                        OUR SENIOR DIRECTOR
                    </div>
                </h1>
                <div
                    className="hero-header"
                    style={{
                        backgroundImage: `url(${coverTeam})`,
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        backgroundPosition: "center",
                    }}
                >
                    <div
                        class="tQfdq"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "70px",
                            padding: "37px 51px",
                            position: "absolute",
                            backgroundColor: "#fff",
                            top: "-38px",
                            left: "-72px",
                            borderBottomRightRadius: "55px",
                            zIndex: 99,
                        }}
                    >
                        <img
                            src="/assets/images/mmh-logo.png"
                            alt="logo"
                            class="logo-web"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                alignItems: "center",
                                height: "60px",
                            }}
                        />
                    </div>
                    <img
                        width="43"
                        height="43"
                        class="tQfdq"
                        style={{
                            position: "absolute",
                            top: "94px",
                            zIndex: "3",
                            left: "-4px",
                        }}
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0M3B4IiB2aWV3Qm94PSIwIDAgNDMgNDMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCA3QDN4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFjY3VlaWwtLS1QYXJ0aWN1bGllcnMtMDEtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0My4wMDAwMDAsIC0yMDcuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00NDMsMjcxIEw0NDMsMjUwLjExOTE4OCBDNDQzLDIyNi4zNzA5NDQgNDYyLjI1MTc1NiwyMDcuMTE5MTg4IDQ4NiwyMDcuMTE5MTg4IEw0ODYsMjA3LjExOTE4OCBMNDg2LDIwNy4xMTkxODggTDQ0MywyMDcuMTkwNDMgTDQ0MywyNzEgWiIgaWQ9IlBhdGgtNyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
                    ></img>
                    <img
                        class="tQfdq"
                        style={{
                            position: "absolute",
                            zIndex: 10,
                            top: "-53.7px",
                            left: "267px",
                            height: "151px",
                        }}
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTlweCIgaGVpZ2h0PSIxNDFweCIgdmlld0JveD0iMCAwIDk5IDE0MSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTggKDg0NjYzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoIDcgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNC1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAyLjAwMDAwMCwgMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMwMiwxNDAuMTE5MTg4IEwzMDIuMjAyNDY0LDE0MC4xMTkxODggQzMyMi4yOTkxMzUsMTQwLjExOTE4OCAzNDAuNjY3MDU1LDEyOC43NTI3MjYgMzQ5LjYzNDA3NiwxMTAuNzY3NDkgTDM2OS43MzczNDUsNzAuNDQ2MTgyMyBDMzc1LjY0NzYsNTguNTkxOTMxNiAzODcuNzU0MDgxLDUxLjEwMDE4MTMgNDAxLDUxLjEwMDE4MTMgTDQwMSw1MS4xMDAxODEzIEw0MDEsNTEuMTAwMTgxMyBMNDAxLDAgTDMwMS45OTM1NzEsMCIgaWQ9IlBhdGgtNy1Db3B5Ij48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
                    />
                </div>
            </section>
            <div class="clearfix"></div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "20px",
                }}
            >
                <img
                    src={management}
                    alt="Ron Monat"
                    width="120px"
                    height="123px"
                />
                <h2
                    class="Question"
                    style={{
                        fontFamily: "philosopher",
                        marginTop: "20px",
                        fontSize: "25px",
                    }}
                >
                    What do you want to know about our Senior Director ?
                </h2>
            </div>
            <div class="team-background">
                <div class="wrapper">
                    <div class="profile-card js-profile-card">
                        <div class="profile-card-r">
                            <div class="profile-card__img">
                                <img
                                    src="/assets/images/Team/jerry.jpg"
                                    alt="profile card"
                                    class="profile_card_img"
                                />
                                <div class="social">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i
                                                    class="fa fa-twitter"
                                                    style={{
                                                        color: "#ffffffa8",
                                                    }}
                                                ></i>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="#">
                                                <i
                                                    class="fa fa-linkedin"
                                                    style={{
                                                        color: "#ffffffa8",
                                                    }}
                                                ></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="profile-card__img">
                            <img
                                src="/assets/images/Team/ron.jpg"
                                alt="profile card"
                                class="profile_card_img"
                            />
                            <div class="social">
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i
                                                class="fa fa-twitter"
                                                style={{ color: "#ffffffa8" }}
                                            ></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#">
                                            <i
                                                class="fa fa-linkedin"
                                                style={{ color: "#ffffffa8" }}
                                            ></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="profile-card__cnt js-profile-cnt">
                            <div
                                class="profile-card__name"
                                style={{ right: "128px" }}
                            >
                                Mr. Ron Monat
                            </div>

                            <div
                                id="rnomnot"
                                class="profile-card__txt"
                                style={{ left: "292px" }}
                            >
                                <strong>Senior Director</strong>
                                <br />
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        // justifyContent: "space-evenly",
                                        marginTop: "10px",
                                        transform: "translateX(56px)",
                                    }}
                                >
                                    <i
                                        class="fa fa-envelope"
                                        style={{
                                            fontSize: "14px",
                                            marginRight: "5px",
                                        }}
                                    ></i>
                                    <p style={{ fontSize: "14px" }}>
                                        ron.monat@outlook.com
                                    </p>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        // justifyContent: "space-evenly",
                                        width: "220px",
                                        transform: "translateX(54px)",
                                    }}
                                >
                                    <i
                                        class="fa fa-phone"
                                        style={{ marginRight: "5px" }}
                                    ></i>

                                    <p style={{ fontSize: "14px" }}>
                                        (602)-295-7880 USA
                                    </p>
                                </div>
                            </div>
                            <div class="profile-card-social">
                                <Swiper
                                    modules={[
                                        Navigation,
                                        Pagination,
                                        Scrollbar,
                                        A11y,
                                    ]}
                                    slidesPerView={1}
                                    navigation
                                    autoplay={{ delay: 5000 }}
                                >
                                    <SwiperSlide>
                                        <p
                                            id="RfA2"
                                            class="CfA"
                                            style={{
                                                fontSize: "16px",
                                                color: "#000",
                                                lineHeight: "1.5",
                                                fontFamily: "georgia",
                                                padding: "49px 50px",
                                                transform: "translateY(25px)",
                                            }}
                                        >
                                            As Senior Director for MMH & ABS
                                            Engineering, I work very closely
                                            with our CEO and President to help
                                            identify opportunities and execute
                                            the plans our team develops. I bring
                                            a broad background in energy and
                                            natural resources having worked in
                                            renewable energy (including solar,
                                            wind, and geothermal), oil & gas,
                                            helium, and precious metals.
                                            Additionally, my in-depth marketing
                                            background allows me to effectively
                                            promote the many programs we have
                                            developed for key areas around the
                                            world.
                                        </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <p
                                            id="RfA2"
                                            class="CfA"
                                            style={{
                                                fontSize: "16px",
                                                color: "#000",
                                                lineHeight: "1.5",
                                                fontFamily: "georgia",
                                                padding: "40px 50px",
                                                transform: "translateY(50px)",
                                            }}
                                        >
                                            Our plans currently call for MMH &
                                            ABS Engineering to develop energy
                                            resources and to consult regarding
                                            investment opportunities and
                                            technical challenges in the United
                                            States. In Years 2 and 3, we will
                                            expand our geographical coverage to
                                            include Africa, the Middle East, and
                                            Southeast Asia. In addition to the
                                            expertise we bring, we will always
                                            promote the humanitarian goals we
                                            value, that of helping, sharing, and
                                            caring.
                                        </p>
                                    </SwiperSlide>
                                    {/* <SwiperSlide>
                                        <p
                                            class="CfA"
                                            style={{
                                                fontSize: "16px",
                                                color: "#000",
                                                lineHeight: "1.5",
                                                fontFamily: "georgia",
                                                padding: "28px 50px",
                                                transform: "translateY(35px)",
                                            }}
                                        >
                                            We have already developed and
                                            presented our Gateway to Africa
                                            program to numerous countries. This
                                            unique program not only works on the
                                            country level but also on the
                                            continent level for all of Africa.
                                            Our Gateway program encompasses the
                                            establishment of The MMH Bank to
                                            facilitate cross-border investment,
                                            a unique crypto currency program
                                            that supports international trade,
                                            energy generation projects, the
                                            development of new city centers and
                                            jobs, free trade zones, world
                                            traveler/tourist opportunities, and
                                            Hajj and Umrah pilgrimage packages.
                                        </p>
                                    </SwiperSlide> */}
                                    <SwiperSlide>
                                        <p
                                            class="CfA"
                                            id="RfA4"
                                            style={{
                                                fontSize: "16px",
                                                color: "#000",
                                                lineHeight: "1.5",
                                                fontFamily: "georgia",
                                                padding: "42px 50px",
                                                transform: "translateY(57px)",
                                            }}
                                        >
                                            Our headquarters will be located in
                                            London since it is one of the most
                                            important financial centers in the
                                            world - and conveniently located
                                            between our offices in Phoenix,
                                            Arizona, and Dubai, UAE. Of course,
                                            we will travel to where our skill
                                            sets can best contribute to making
                                            the world a better place for those
                                            who need our help.
                                        </p>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filters">
                <div class="b-filter">
                    <Link to="/management/jerry">
                        <img
                            src="/assets/images/Team/jerry.jpg"
                            alt="Team"
                            class="icon"
                            style={{ borderRadius: "50%" }}
                        />
                        <span class="name">Dr. Gerald Bailey</span>
                    </Link>
                </div>
                <div class="b-filter">
                    <Link to="/management/shahid">
                        <img
                            src="/assets/images/Team/sidi.jpg"
                            alt="Team"
                            class="icon"
                            style={{ borderRadius: "50%" }}
                        />
                        <span class="name">Mr. Sidi Mehmud</span>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default RonDetails;
