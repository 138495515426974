import { Link } from "react-router-dom";
import "./HeadFooter.css";
const HeadFooter = () => {
  const date = new Date(Date.now());
  let year = date.getFullYear();
  return (
    <footer>
      <div class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <ul>
                <li>
                  <a href="" class="no-link">
                    Others
                  </a>
                  <ul>
                    <li>
                      <Link to="/FAQ">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/partners">Partners</Link>
                    </li>
                    <li>
                      <Link to="/digitalTransformation">
                        Digital Transformation in Oil and Gas
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="col-lg-4">
              <div
                id="block-socialmedialinks-2"
                class="block-social-media-links"
              >
                <ul>
                  <li>
                    <a href="" class="no-link">
                      Useful Links
                    </a>
                  </li>
                </ul>

                <ul>
                  <li>
                    <Link to="/objectivesandstrategies">About Us</Link>
                  </li>
                  <li>
                    <Link to="/objectivesandstrategies">Our Objectives</Link>
                  </li>
                  <li>
                    <Link to="/sampleproject">Sample Projects</Link>
                  </li>

                  {/* <li>
                                        <a
                                            class="social-media-link-icon--instagram"
                                            href="#"
                                            target="_blank"
                                        >
                                            <span class="fa fa-instagram fa-2x"></span>
                                        </a>
                                    </li> */}
                </ul>
              </div>
            </div>
            <div class="col-lg-4">
              <div
                id="block-socialmedialinks-2"
                class="block-social-media-links"
              >
                <ul>
                  <li>
                    <a href="" class="no-link">
                      Follow Us
                    </a>
                  </li>
                </ul>

                <ul class="social-media-links--platforms platforms inline horizontal">
                  <li>
                    <a
                      class="social-media-link-icon--facebook"
                      href="#"
                      target="_blank"
                    >
                      <span class="fa fa-facebook fa-2x"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="social-media-link-icon--twitter"
                      href="#"
                      target="_blank"
                    >
                      <span class="fa fa-twitter fa-2x"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="social-media-link-icon--linkedin"
                      href="#"
                      target="_blank"
                    >
                      <span class="fa fa-linkedin fa-2x"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="social-media-link-icon--youtube"
                      href="#"
                      target="_blank"
                    >
                      <span class="fa fa-youtube fa-2x"></span>
                    </a>
                  </li>
                  {/* <li>
                                        <a
                                            class="social-media-link-icon--instagram"
                                            href="#"
                                            target="_blank"
                                        >
                                            <span class="fa fa-instagram fa-2x"></span>
                                        </a>
                                    </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sub-footer">
        <div class="container footer-description">
          <div class="block-content">
            <div class="field_logo_left">
              <img
                src="/assets/images/slogan.png"
                // width="90"
                height="41"
              />
            </div>

            <div>
              <div class="field_title_simple">© {year} MMH-ABS</div>
            </div>

            <div class="field_logo_right">
              <img
                src="/mmh-logo.ico"
                width="149"
                height="44"
                typeof="foaf:Image"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div
                class="modal fade"
                id="testimonialModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="testimonialModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div
                                id="testimonialVideo"
                                class="embed-responsive embed-responsive-16by9"
                            ></div>
                        </div>
                    </div>
                </div>
            </div> */}
    </footer>
  );
};

export default HeadFooter;
