import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import "./style.css";
import coverTeam from "../../images/coverTeam.png";
import management from "./management.png";

const ShahidDetails = () => {
    SwiperCore.use([Autoplay]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <section class="main-head">
                <div
                    class="main-menu-wrapper"
                    style={{ position: "absolute", left: "371px" }}
                >
                    <ul class="c-menu-main-menu" style={{ zIndex: 999 }}>
                        <li>
                            <Link to="/" class="link">
                                <div class="link-before">
                                    <img
                                        width="54"
                                        height="61"
                                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                                    ></img>
                                </div>

                                <span>Home</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/objectivesandstrategies" class="link">
                                <div class="link-before">
                                    <img
                                        width="54"
                                        height="61"
                                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                                    ></img>
                                </div>
                                <span style={{ width: "262px" }}>
                                    Our Strategies And Objectives
                                </span>
                            </Link>
                            <img
                                class="tQfdq"
                                style={{
                                    top: "-2px",
                                    position: "absolute",
                                    zIndex: 10,
                                    left: "184px",
                                    height: "70px",
                                }}
                                width="54"
                                height="61"
                                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
                            ></img>
                        </li>
                    </ul>
                </div>
                <ul class="nav red-4 navbar-nav">
                    <li
                        style={{
                            position: "absolute",
                            right: "50px",
                            top: "-34px",
                        }}
                    >
                        <div class="dropdown">
                            <span>Arabic</span>

                            <div class="dropdown-content">
                                <p>English</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <h1>
                    <div
                        class="field_title_header"
                        style={{
                            position: "absolute",
                            top: "196px",
                            zIndex: 1,
                            right: "539px",
                            fontSize: "48px",
                            fontWeight: 700,
                            fontFamily: "Philosopher",
                            color: "#fff",
                        }}
                    >
                        OUR PRESIDENT
                    </div>
                </h1>
                <div
                    className="hero-header"
                    style={{
                        backgroundImage: `url(${coverTeam})`,
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        backgroundPosition: "center",
                    }}
                >
                    <div
                        class="tQfdq"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "70px",
                            padding: "37px 51px",
                            position: "absolute",
                            backgroundColor: "#fff",
                            top: "-38px",
                            left: "-72px",
                            borderBottomRightRadius: "55px",
                            zIndex: 99,
                        }}
                    >
                        <img
                            src="/assets/images/mmh-logo.png"
                            alt="logo"
                            class="logo-web"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                alignItems: "center",
                                height: "60px",
                            }}
                        />
                    </div>
                    <img
                        width="43"
                        height="43"
                        class="tQfdq"
                        style={{
                            position: "absolute",
                            top: "94px",
                            zIndex: "3",
                            left: "-4px",
                        }}
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0M3B4IiB2aWV3Qm94PSIwIDAgNDMgNDMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCA3QDN4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFjY3VlaWwtLS1QYXJ0aWN1bGllcnMtMDEtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0My4wMDAwMDAsIC0yMDcuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00NDMsMjcxIEw0NDMsMjUwLjExOTE4OCBDNDQzLDIyNi4zNzA5NDQgNDYyLjI1MTc1NiwyMDcuMTE5MTg4IDQ4NiwyMDcuMTE5MTg4IEw0ODYsMjA3LjExOTE4OCBMNDg2LDIwNy4xMTkxODggTDQ0MywyMDcuMTkwNDMgTDQ0MywyNzEgWiIgaWQ9IlBhdGgtNyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
                    ></img>
                    <img
                        class="tQfdq"
                        style={{
                            position: "absolute",
                            zIndex: 10,
                            top: "-53.7px",
                            left: "267px",
                            height: "151px",
                        }}
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTlweCIgaGVpZ2h0PSIxNDFweCIgdmlld0JveD0iMCAwIDk5IDE0MSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTggKDg0NjYzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoIDcgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNC1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAyLjAwMDAwMCwgMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMwMiwxNDAuMTE5MTg4IEwzMDIuMjAyNDY0LDE0MC4xMTkxODggQzMyMi4yOTkxMzUsMTQwLjExOTE4OCAzNDAuNjY3MDU1LDEyOC43NTI3MjYgMzQ5LjYzNDA3NiwxMTAuNzY3NDkgTDM2OS43MzczNDUsNzAuNDQ2MTgyMyBDMzc1LjY0NzYsNTguNTkxOTMxNiAzODcuNzU0MDgxLDUxLjEwMDE4MTMgNDAxLDUxLjEwMDE4MTMgTDQwMSw1MS4xMDAxODEzIEw0MDEsNTEuMTAwMTgxMyBMNDAxLDAgTDMwMS45OTM1NzEsMCIgaWQ9IlBhdGgtNy1Db3B5Ij48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
                    />
                    {/* <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-lg-12 z-1  simple ">
                                <h1>
                                    <div className="field_title_header">
                                        Our Objectives & Strategies
                                    </div>
                                </h1>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    <img
                        src={management}
                        alt="Ceo"
                        width="120px"
                        height="123px"
                    />
                    <h2
                        class="Question"
                        style={{
                            fontFamily: "philosopher",
                            marginTop: "20px",
                            fontSize: "25px",
                        }}
                    >
                        What do you want to know about our President ?
                    </h2>
                </div>
            </section>
            <div class="clearfix"></div>

            <div class="team-background">
                <div class="wrapper">
                    <div class="profile-card js-profile-card">
                        <div class="profile-card-r">
                            <div class="profile-card__img">
                                <img
                                    src="/assets/images/Team/sidi.jpg"
                                    alt="profile card"
                                    class="profile_card_img"
                                />
                                <div class="social">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i
                                                    class="fa fa-twitter"
                                                    style={{
                                                        color: "#ffffffa8",
                                                    }}
                                                ></i>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="#">
                                                <i
                                                    class="fa fa-linkedin"
                                                    style={{
                                                        color: "#ffffffa8",
                                                    }}
                                                ></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="profile-card__cnt js-profile-cnt">
                            <div
                                class="profile-card__name"
                                id="msidi"
                                style={{ right: "93px" }}
                            >
                                Mr. Sidi Mehmud
                            </div>
                            <div class="profile-card__txt">
                                <strong
                                    style={{
                                        position: "absolute",
                                        left: "11px",
                                    }}
                                >
                                    President
                                </strong>
                                <br />
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        justifyContent: "space-evenly",
                                        marginTop: "10px",
                                        width: "210px",
                                    }}
                                >
                                    <i
                                        class="fa fa-envelope"
                                        style={{ fontSize: "14px" }}
                                    ></i>
                                    <p style={{ fontSize: "14px" }}>
                                        sidiShahiduk@gmail.com
                                    </p>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        justifyContent: "space-evenly",
                                        width: "168px",
                                    }}
                                >
                                    <i class="fa fa-phone"></i>
                                    <p style={{ fontSize: "14px" }}>
                                        +44 (7783) 904941
                                    </p>
                                </div>
                            </div>

                            <div class="profile-card-social">
                                <Swiper
                                    modules={[
                                        Navigation,
                                        Pagination,
                                        Scrollbar,
                                        A11y,
                                    ]}
                                    slidesPerView={1}
                                    navigation
                                    autoplay={{ delay: 5000 }}
                                >
                                    <SwiperSlide>
                                        <p
                                            class="CfA"
                                            id="fC1"
                                            style={{
                                                fontSize: "16px",
                                                color: "#000",
                                                lineHeight: "1.5",
                                                fontFamily: "georgia",
                                                padding: "49px 50px",
                                                transform: "translateY(40px)",
                                            }}
                                        >
                                            As the founder and President of MMH
                                            & ABS Engineering Group, I am
                                            extremely grateful to have the
                                            opportunity to bring together two of
                                            the things I love most in this
                                            world, technology and the
                                            opportunity to improve the lives of
                                            others. What could be more
                                            gratifying than helping those less
                                            fortunate than ourselves, especially
                                            with the solutions modern technology
                                            offers.
                                        </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <p
                                            class="CfA"
                                            style={{
                                                fontSize: "16px",
                                                color: "#000",
                                                lineHeight: "1.5",
                                                fontFamily: "georgia",
                                                padding: "40px 50px",
                                                transform: "translateY(40px)",
                                            }}
                                        >
                                            I have lived and worked in Africa
                                            several times during the past
                                            fifteen years. What I saw was truly
                                            awe-inspiring . . . as well as
                                            disappointing. I had the honor to
                                            visit with wonderful, hard-working
                                            people in some of the most beautiful
                                            countries in the world.
                                            Unfortunately, my excitement and
                                            pleasure were equally offset by the
                                            poverty and suffering I saw all
                                            around me. I learned about the
                                            terrible abuses of the people and
                                            their lands at the hands of
                                            foreigners over many decades.
                                        </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <p
                                            class="CfA"
                                            style={{
                                                fontSize: "16px",
                                                color: "#000",
                                                lineHeight: "1.5",
                                                fontFamily: "georgia",
                                                padding: "20px 50px",
                                                transform: "translateY(42px)",
                                            }}
                                        >
                                            I wondered many times about how I
                                            could help with working conditions,
                                            housing, education, healthcare, and
                                            nutrition for so many Africans in
                                            need. I thought about how young
                                            Africans could find opportunities
                                            and improvements in all these areas.
                                            Hard work and divine guidance have
                                            shown me numerous ways to improve
                                            this world starting with the very
                                            ground on which Africans walk every
                                            day. I truly believe that the
                                            fastest path of improvement in
                                            Africa starts with the economic
                                            development of the natural resources
                                            with which Africa is so blessed!.
                                        </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <p
                                            class="CfA"
                                            style={{
                                                fontSize: "16px",
                                                color: "#000",
                                                lineHeight: "1.5",
                                                fontFamily: "georgia",
                                                padding: "30px 50px",
                                                transform: "translateY(42px)",
                                            }}
                                        >
                                            I know that nothing happens on a
                                            large scale without investment. I
                                            have focused on investment and
                                            development strategies that can
                                            create wealth for regular men and
                                            women in Africa, not just those who
                                            have already made their fortunes and
                                            care little about helping others. As
                                            noted above, Africa’s natural
                                            resources are in high demand in the
                                            wealthier countries that value
                                            technology and require the unique
                                            minerals and precious metals these
                                            technologies require.
                                        </p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <p
                                            class="CfA"
                                            style={{
                                                fontSize: "16px",
                                                color: "#000",
                                                lineHeight: "1.5",
                                                fontFamily: "georgia",
                                                padding: "28px 50px",
                                                transform: "translateY(58px)",
                                            }}
                                        >
                                            I feel very blessed to have been
                                            given the opportunity to make a
                                            difference in the lives of so many
                                            people living in both developed and
                                            developing nations around the world.
                                        </p>
                                    </SwiperSlide>
                                    {/* <SwiperSlide>
                                        <p
                                            class="CfA"
                                            style={{
                                                fontSize: "16px",
                                                color: "#000",
                                                lineHeight: "1.5",
                                                fontFamily: "georgia",
                                                padding: "28px 50px",
                                                transform: "translateY(45px)",
                                            }}
                                        >
                                            I am also very excited to announce
                                            the development of a unique line of
                                            cryptocurrencies that will be issued
                                            by The Bank of Makkah & Al Madinah.
                                            While we will announce our “Crypto
                                            Currency” later this year, it is too
                                            early to share all the exciting
                                            innovations that will make ours
                                            extra secure and well-positioned to
                                            finance energy, industrial, natural
                                            resource, and food commodities. As
                                            with our other ventures, a portion
                                            of the profits will be donated to
                                            charitable causes.
                                        </p>
                                    </SwiperSlide> */}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filters">
                <div class="b-filter">
                    <Link to="/management/jerry">
                        <img
                            src="/assets/images/Team/jerry.jpg"
                            alt="Team"
                            class="icon"
                            style={{ borderRadius: "50%" }}
                        />
                        <span class="name">Dr. Gerald Bailey</span>
                    </Link>
                </div>

                <div class="b-filter">
                    <Link to="/management/ron">
                        <img
                            src="/assets/images/Team/ron.jpg"
                            alt="Team"
                            class="icon"
                            style={{ borderRadius: "50%" }}
                        />
                        <span class="name">Ron Monat</span>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ShahidDetails;
