import { Route, Switch, Link } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import ContactUs from "./Components/ContactUs";
import Main from "./pages/Main";
import JerryDetails from "./Components/Team/Jerrydetails";
import ShahidDetails from "./Components/Team/ShahidDetails";
import RonDetails from "./Components/Team/RonDetails";
import MessageCeo from "./Components/Message/MessageCeo";
import Projects from "./Components/Projects";
import { useEffect, useState } from "react";
import DigitalTransformation from "./Components/DigitalTransformation";
import FAQ from "./Components/FAQ";
import Partners from "./Components/Partners";
import ObjectivesAndStrategies from "./Components/ObjectivesAndStrategies";
import SimpleProject from "./Components/SimpleProject";
import Cities from "./Components/Message/Cities";

function App() {
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset < 160) {
          document
            .getElementById("light-menu-sticky")
            .classList.add("animate__fadeOutUp");
        } else {
          setShowHeader(window.pageYOffset > 151);
          document
            .getElementById("light-menu-sticky")
            .classList.remove("animate__fadeOutUp");
        }
      });
    }
  }, []);
  const menu_mobile = () => {
    let main_wrapper = document.getElementById("mobile-nav");
    if (main_wrapper.getAttribute("style").includes("none")) {
      main_wrapper.style.display = "block";
    } else if (main_wrapper.getAttribute("style").includes("block")) {
      main_wrapper.style.display = "none";
    }
  };
  const CloseNav = () => {
    let main_wrapper = document.getElementById("mobile-nav");
    main_wrapper.style.display = "none";
  };
  return (
    <>
      <div class="top-header">
        <div class="bloc-logo">
          <span class="hamburger-menu" onClick={menu_mobile}>
            <span class="burger-1"></span>
            <span class="burger-2"></span>
            <span class="burger-3"></span>
          </span>

          <div class="site-logo">
            <div id="block-particuliers-branding">
              <a href="#" title="Accueil" rel="home">
                <img
                  src="/assets/images/mmh-logo.png"
                  alt="Accueil"
                  width="180px"
                  height="40px"
                  class="brand-logo"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="bloc-menu">
          <div class="main-menu">
            <div
              id="mobile-nav"
              class="main-menu-wrapper "
              style={{ display: "none" }}
            >
              <ul class="c-menu-main-menu">
                <li>
                  <Link to="/" class="link" onClick={CloseNav}>
                    <div class="link-before">
                      <img
                        width="54"
                        height="61"
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                      ></img>
                    </div>
                    <span>Home</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/objectivesandstrategies"
                    class="link"
                    onClick={CloseNav}
                  >
                    <div class="link-before">
                      <img
                        width="54"
                        height="61"
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                      ></img>
                    </div>
                    <span>Our Strategies And Objectives</span>
                  </Link>
                </li>
              </ul>

              <div
                class="block-language"
                onClick={CloseNav}
                style={{ padding: "6px 0px" }}
              >
                <div class="dropbutton-wrapper dropbutton-single">
                  <div class="dropbutton-widget">
                    <ul class="satoripop-language-item dropbutton">
                      <li class="ar dropbutton-action">
                        <a href="#" class="language-link">
                          Arabic
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showHeader ? (
        <div
          class="light-menu animate__animated animate__fadeInDown "
          id="light-menu-sticky"
          style={{ top: "0px" }}
        >
          <div id="block-marquedusite-3">
            <a href="#" title="Accueil" rel="home">
              <img
                src="/assets/images/mmh-logo.png"
                alt="Accueil"
                class="brand-logo"
                style={{ display: "block", height: "40px" }}
              />
            </a>
          </div>

          <ul class="c-menu-main-menu">
            <li>
              <Link to="/">
                <a href="#" class="link">
                  <div class="link-before">
                    <img
                      width="54"
                      height="61"
                      src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                    ></img>
                  </div>
                  <span>Home</span>
                </a>
              </Link>
            </li>
            <li>
              <Link to="/objectivesandstrategies" class="link">
                <div class="link-before">
                  <img
                    width="54"
                    height="61"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                  ></img>
                </div>
                <span style={{ width: "262px" }}>
                  Our Strategies And Objectives
                </span>
              </Link>
              <img
                style={{
                  top: "-30px",
                  position: "absolute",
                  zIndex: 10,
                  left: "161px",
                  height: "109px",
                  transform: "scale(1.3)",
                }}
                width="54"
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
              ></img>
            </li>
          </ul>
        </div>
      ) : null}
      <div class="sticky-menu nav-down" style={{ zIndex: "1000" }}>
        <ul>
          <li>
            <Link
              to="/partners"
              class="others"
              data-drupal-link-system-path="node/622"
            >
              Others
            </Link>
          </li>
          <li>
            <Link
              to="/ceo"
              class="aboutus"
              data-drupal-link-system-path="node/616"
            >
              About Us
            </Link>
          </li>
          <li>
            <Link to="/contact" class="contactus">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      <Switch>
        <Layout>
          <Route path="/management/jerry" component={JerryDetails} />
          <Route path="/management/ron" component={RonDetails} />
          <Route path="/management/shahid" component={ShahidDetails} />
          <Route path="/ceo" component={MessageCeo} />
          <Route path="/unitedkingdom" component={Cities} />
          <Route path="/strategies" component={Projects} />

          <Route path="/contact" component={ContactUs} />
          <Route exact path="/" component={Main} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/partners" component={Partners} />
          <Route
            exact
            path="/digitalTransformation"
            component={DigitalTransformation}
          />
          <Route
            path="/objectivesandstrategies"
            component={ObjectivesAndStrategies}
          />
          <Route path="/sampleproject" component={SimpleProject} />
        </Layout>
      </Switch>
    </>
  );
}

export default App;
