import Carousel from "../Components/Carousel";

import Block from "../Components/Block";
import { useEffect, useState } from "react";
import "./Main.css";
import WhatWeDo from "../Components/WhatWeDo/WhatWeDo";
import Wwd from "../Components/WhatWeDo/Wwd";
import Aboutus from "../Components/AboutsUS2/Aboutus";
import WOW from "wowjs";
import Team from "../Components/TeamComponent";

const Main = () => {
  const [wwd, setWwd] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  setInterval(() => {
    document.querySelector("body").offsetWidth > 1230
      ? setWwd(true)
      : setWwd(false);
  }, 500);
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <>
      <Carousel />
      <Block />

      <Aboutus />
      {wwd ? <Wwd /> : <WhatWeDo />}

      <Team />
    </>
  );
};

export default Main;
