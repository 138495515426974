import { Link } from "react-router-dom";
import { useEffect } from "react";
import WOW from "wowjs";
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <>
      <section class="main-head">
        <ul class="nav red-4 navbar-nav">
          <li
            style={{
              position: "absolute",
              right: "50px",
              top: "-34px",
            }}
          >
            <div class="dropdown">
              <span>Arabic</span>

              <div class="dropdown-content">
                <p>English</p>
              </div>
            </div>
          </li>
        </ul>
        <div
          class="main-menu-wrapper"
          style={{ position: "absolute", left: "371px" }}
        >
          <ul class="c-menu-main-menu" style={{ zIndex: 999 }}>
            <li>
              <a href="#" class="link">
                <div class="link-before">
                  <img
                    width="54"
                    height="61"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                  ></img>
                </div>
                <Link to="/">
                  <span>Home</span>
                </Link>
              </a>
            </li>
            <li>
              <Link to="/objectivesandstrategies" class="link">
                <div class="link-before">
                  <img
                    width="54"
                    height="61"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                  ></img>
                </div>
                <span style={{ width: "262px" }}>
                  Our Strategies And Objectives
                </span>
              </Link>
              <img
                class="tQfdq"
                style={{
                  top: "-2px",
                  position: "absolute",
                  zIndex: 10,
                  left: "184px",
                  height: "70px",
                }}
                width="54"
                height="61"
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
              ></img>
            </li>
          </ul>
        </div>
        <h1>
          <div
            class="field_title_header"
            style={{
              position: "absolute",
              top: "196px",
              zIndex: 1,
              right: "600px",
              fontSize: "38px",
              fontWeight: 700,
              fontFamily: "Philosopher",
              color: "#fff",
            }}
          >
            Contact Us
          </div>
        </h1>
        <div
          class="hero-header"
          style={{
            backgroundImage: "url(/assets/images/contactUs.jpg)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            backgroundPosition: "center",
          }}
        >
          <div
            class="tQfdq"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "70px",
              padding: "37px 51px",
              position: "absolute",
              backgroundColor: "#fff",
              top: "-38px",
              left: "-72px",
              borderBottomRightRadius: "55px",
              zIndex: 99,
            }}
          >
            <img
              src="/assets/images/mmh-logo.png"
              alt="logo"
              class="tQfdq"
              style={{
                display: "flex",
                alignItems: "center",
                alignItems: "center",
                height: "60px",
              }}
            />
          </div>
          <img
            class="tQfdq"
            width="43"
            height="43"
            style={{
              position: "absolute",
              top: "94px",
              zIndex: "3",
              left: "-4px",
            }}
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0M3B4IiB2aWV3Qm94PSIwIDAgNDMgNDMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCA3QDN4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFjY3VlaWwtLS1QYXJ0aWN1bGllcnMtMDEtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0My4wMDAwMDAsIC0yMDcuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00NDMsMjcxIEw0NDMsMjUwLjExOTE4OCBDNDQzLDIyNi4zNzA5NDQgNDYyLjI1MTc1NiwyMDcuMTE5MTg4IDQ4NiwyMDcuMTE5MTg4IEw0ODYsMjA3LjExOTE4OCBMNDg2LDIwNy4xMTkxODggTDQ0MywyMDcuMTkwNDMgTDQ0MywyNzEgWiIgaWQ9IlBhdGgtNyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
          ></img>
          <img
            class="tQfdq"
            style={{
              position: "absolute",
              zIndex: 10,
              top: "-53.7px",
              left: "267px",
              height: "151px",
            }}
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTlweCIgaGVpZ2h0PSIxNDFweCIgdmlld0JveD0iMCAwIDk5IDE0MSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTggKDg0NjYzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoIDcgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNC1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAyLjAwMDAwMCwgMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMwMiwxNDAuMTE5MTg4IEwzMDIuMjAyNDY0LDE0MC4xMTkxODggQzMyMi4yOTkxMzUsMTQwLjExOTE4OCAzNDAuNjY3MDU1LDEyOC43NTI3MjYgMzQ5LjYzNDA3NiwxMTAuNzY3NDkgTDM2OS43MzczNDUsNzAuNDQ2MTgyMyBDMzc1LjY0NzYsNTguNTkxOTMxNiAzODcuNzU0MDgxLDUxLjEwMDE4MTMgNDAxLDUxLjEwMDE4MTMgTDQwMSw1MS4xMDAxODEzIEw0MDEsNTEuMTAwMTgxMyBMNDAxLDAgTDMwMS45OTM1NzEsMCIgaWQ9IlBhdGgtNy1Db3B5Ij48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
          />
          {/* <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-lg-12 z-1  simple ">
                                <h1>
                                    <div className="field_title_header">
                                        Our Objectives & Strategies
                                    </div>
                                </h1>
                            </div>
                        </div>
                    </div> */}
        </div>
      </section>

      <section class="sec-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="smart-forms bmargin">
                <h3 class="raleway wow fadeInUp" data-wow-delay="0.3s">
                  Contact Us
                </h3>

                <br />
                <br />
                <form>
                  <div>
                    <div class="section wow fadeInLeft" data-wow-delay="0.3s">
                      <label class="field prepend-icon">
                        <input
                          type="text"
                          name="fullName"
                          class="gui-input"
                          placeholder="Enter your name"
                        />
                        <span class="field-icon">
                          <i class="fa fa-user"></i>
                        </span>
                      </label>
                    </div>
                    <div class="section wow fadeInLeft" data-wow-delay="0.4s">
                      <label class="field prepend-icon">
                        <input
                          type="email"
                          name="email"
                          class="gui-input"
                          placeholder="jhon@doe.com"
                        />
                        <span class="field-icon">
                          <i class="fa fa-envelope"></i>
                        </span>
                      </label>
                    </div>
                    <div
                      class="section colm colm6 wow fadeInLeft"
                      data-wow-delay="0.5s"
                    >
                      <label class="field prepend-icon">
                        <input
                          type="tel"
                          name="phoneNumber"
                          class="gui-input"
                          placeholder="Telephone number"
                        />
                        <span class="field-icon">
                          <i class="fa fa-phone-square"></i>
                        </span>
                      </label>
                    </div>
                    <div class="section wow fadeInLeft" data-wow-delay="0.6s">
                      <label class="field prepend-icon">
                        <input
                          type="text"
                          name="subjectMail"
                          class="gui-input"
                          placeholder="Enter mail subjec"
                        />
                        <span class="field-icon">
                          <i class="fa fa-lightbulb-o"></i>
                        </span>
                      </label>
                    </div>
                    <div class="section wow fadeInLeft" data-wow-delay="0.7s">
                      <label class="field prepend-icon">
                        <textarea
                          class="gui-textarea"
                          name="text"
                          placeholder="Enter your message"
                        ></textarea>
                        <span class="field-icon">
                          <i class="fa fa-comments"></i>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="form-footer">
                    <button
                      class="button btn-primary wow fadeInLeft"
                      data-wow-delay="0.8s"
                      style={{
                        backgroundColor: " #2968b2",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-4 bmargin">
              <h3 class="raleway wow fadeInRight" data-wow-delay="0.3s">
                Address Info
              </h3>
              <h6 className="wow fadeInRight" data-wow-delay="0.4s">
                <strong>MMH-ABS Engineering Group LTD</strong>
              </h6>
              <span className="wow fadeInRight" data-wow-delay="0.5s">
                71-75 Shelton Street , Covent Garden , London,WC2H 9jQ
              </span>
              <br />
              <span className="wow fadeInRight" data-wow-delay="0.6s">
                Uk : +44 (020)-8168-9975
              </span>
              <br />
              {/* Dubai: +971 50 307 0724 */}
              <br />
              <br />
              <span className="wow fadeInRight" data-wow-delay="0.7s">
                E-mail:{" "}
                <a
                  href="mailto:info@mmhabs.com"
                  className="wow fadeInRight"
                  data-wow-delay="0.7s"
                >
                  info@mmhabs.com
                </a>
              </span>
              <br />
              <br />
              <h3 class="raleway wow fadeInRight" data-wow-delay="0.8s">
                Find the Address
              </h3>
              <div class="map wow fadeInRight" data-wow-delay="0.s">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1519.1951744288212!2d-0.12451111116003437!3d51.514957551624136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ccab37652b%3A0x22220c3f07824033!2sShop%2071%2C%20ALG%20ID%20Cards%20t%2Fa%20The%20Lanyard%2C%2075%20Shelton%20St%2C%20London%20WC2H%209JQ%2C%20Royaume-Uni!5e0!3m2!1sfr!2stn!4v1647687178035!5m2!1sfr!2stn"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
