import Navbar from "../Navbar";
import Footer from "../Footer";
import HeadFooter from "../HeadFooter";
const Layout = ({ children }) => {
    return (
        <>
            {/* <Navbar /> */}
            {children}
            <HeadFooter />
        </>
    );
};
export default Layout;
