import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./FAQ.css";
import Faq from "../images/FAQ.png";
const FAQ = () => {
    const showTable = (id) => {
        const element = document
            .getElementById(id)
            .getAttribute("style")
            .includes("none");
        element
            ? (document.querySelector(`#${id}`).style.display = "block")
            : (document.querySelector(`#${id}`).style.display = "none");
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <section class="main-head">
                <ul class="nav red-4 navbar-nav">
                    <li
                        style={{
                            position: "absolute",
                            right: "50px",
                            top: "-34px",
                        }}
                    >
                        <div class="dropdown">
                            <span>Arabic</span>

                            <div class="dropdown-content">
                                <p>English</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <div
                    class="main-menu-wrapper"
                    style={{ position: "absolute", left: "371px" }}
                >
                    <ul class="c-menu-main-menu" style={{ zIndex: 999 }}>
                        <li>
                            <a href="#" class="link">
                                <div class="link-before">
                                    <img
                                        width="54"
                                        height="61"
                                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                                    ></img>
                                </div>
                                <Link to="/">
                                    <span>Home</span>
                                </Link>
                            </a>
                        </li>
                        <li>
                            <Link to="/objectivesandstrategies" class="link">
                                <div class="link-before">
                                    <img
                                        width="54"
                                        height="61"
                                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                                    ></img>
                                </div>
                                <span style={{ width: "262px" }}>
                                    Our Strategies And Objectives
                                </span>
                            </Link>
                            <img
                                class="tQfdq"
                                style={{
                                    top: "-2px",
                                    position: "absolute",
                                    zIndex: 10,
                                    left: "184px",
                                    height: "70px",
                                }}
                                width="54"
                                height="61"
                                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
                            ></img>
                        </li>
                    </ul>
                </div>
                <h1>
                    <div
                        id="faq"
                        class="field_title_header"
                        style={{
                            position: "absolute",
                            top: "196px",
                            zIndex: 1,
                            right: "600px",
                            fontSize: "58px",
                            fontWeight: 700,
                            fontFamily: "Philosopher",
                            color: "#fff",
                        }}
                    >
                        FAQ
                    </div>
                </h1>
                <div
                    id="faq-hero-header"
                    class="hero-header"
                    style={{
                        backgroundImage: "url(/assets/images/FAQ_cover.png)",
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        backgroundPosition: "center",
                    }}
                >
                    <div
                        class="tQfdq"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "70px",
                            padding: "37px 51px",
                            position: "absolute",
                            backgroundColor: "#fff",
                            top: "-38px",
                            left: "-72px",
                            borderBottomRightRadius: "55px",
                            zIndex: 99,
                        }}
                    >
                        <img
                            src="/assets/images/mmh-logo.png"
                            alt="logo"
                            class="tQfdq"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                alignItems: "center",
                                height: "60px",
                            }}
                        />
                    </div>
                    <img
                        class="tQfdq"
                        width="43"
                        height="43"
                        style={{
                            position: "absolute",
                            top: "94px",
                            zIndex: "3",
                            left: "-4px",
                        }}
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0M3B4IiB2aWV3Qm94PSIwIDAgNDMgNDMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCA3QDN4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFjY3VlaWwtLS1QYXJ0aWN1bGllcnMtMDEtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0My4wMDAwMDAsIC0yMDcuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00NDMsMjcxIEw0NDMsMjUwLjExOTE4OCBDNDQzLDIyNi4zNzA5NDQgNDYyLjI1MTc1NiwyMDcuMTE5MTg4IDQ4NiwyMDcuMTE5MTg4IEw0ODYsMjA3LjExOTE4OCBMNDg2LDIwNy4xMTkxODggTDQ0MywyMDcuMTkwNDMgTDQ0MywyNzEgWiIgaWQ9IlBhdGgtNyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
                    ></img>
                    <img
                        class="tQfdq"
                        style={{
                            position: "absolute",
                            zIndex: 10,
                            top: "-53.7px",
                            left: "267px",
                            height: "151px",
                        }}
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTlweCIgaGVpZ2h0PSIxNDFweCIgdmlld0JveD0iMCAwIDk5IDE0MSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTggKDg0NjYzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoIDcgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNC1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAyLjAwMDAwMCwgMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMwMiwxNDAuMTE5MTg4IEwzMDIuMjAyNDY0LDE0MC4xMTkxODggQzMyMi4yOTkxMzUsMTQwLjExOTE4OCAzNDAuNjY3MDU1LDEyOC43NTI3MjYgMzQ5LjYzNDA3NiwxMTAuNzY3NDkgTDM2OS43MzczNDUsNzAuNDQ2MTgyMyBDMzc1LjY0NzYsNTguNTkxOTMxNiAzODcuNzU0MDgxLDUxLjEwMDE4MTMgNDAxLDUxLjEwMDE4MTMgTDQwMSw1MS4xMDAxODEzIEw0MDEsNTEuMTAwMTgxMyBMNDAxLDAgTDMwMS45OTM1NzEsMCIgaWQ9IlBhdGgtNy1Db3B5Ij48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
                    />
                </div>
            </section>

            <div class="faq-block" style={{ minHeight: "400px" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                    }}
                >
                    <img src={Faq} alt="FAQ" width="120px" height="110px" />
                    {/* <h2
                        class="Question"
                        style={{
                            fontFamily: "philosopher",
                            marginTop: "20px",
                            marginBottom: "40px",
                            fontSize: "25px",
                        }}
                    >
                        What are the most popular frequently asked questions ?
                    </h2> */}
                </div>
                <h2 style={{ textAlign: "center" }}>Under Construction</h2>
                {/* <div class="views-infinite-scroll-content-wrapper clearfix">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="item-list">
                                    <ul>
                                        <li>
                                            <div class="views-field views-field-field-faq">
                                                <div class="field-content">
                                                    <div
                                                        id="faqfield_field_faq_node_239"
                                                        class="home-accordion ui-accordion ui-widget ui-helper-reset"
                                                    >
                                                        <h3 class="faqfield-question ui-accordion-header ui-corner-top ui-state-default ui-accordion-icons ui-accordion-header-collapsed ui-corner-all">
                                                            <div
                                                                class="b-bt"
                                                                onClick={() =>
                                                                    showTable(
                                                                        "id-1"
                                                                    )
                                                                }
                                                            >
                                                                What countries
                                                                are the top
                                                                producers of
                                                                oil?
                                                            </div>
                                                        </h3>
                                                        <div
                                                            class="faqfield-answer ui-accordion-content ui-corner-bottom ui-helper-reset ui-widget-content animate__animated animate__fadeIn"
                                                            id="id-1"
                                                            style={{
                                                                display: "none",
                                                            }}
                                                        >
                                                            <div class="table">
                                                                <div class="tableRow header green">
                                                                    <div class="cell">
                                                                        Country
                                                                    </div>
                                                                    <div class="cell">
                                                                        Million
                                                                        barrels
                                                                        per day
                                                                    </div>
                                                                    <div class="cell">
                                                                        Share of
                                                                        world
                                                                        total
                                                                    </div>
                                                                </div>

                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        United
                                                                        States
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        18.61{" "}
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        20%
                                                                    </div>
                                                                </div>

                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Saudi
                                                                        Arabia
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        10.81
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        12%
                                                                    </div>
                                                                </div>

                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Russia
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        10.50
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        11%
                                                                    </div>
                                                                </div>

                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Canada
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        5.23
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        6%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        China
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        4.86
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        5%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Iraq
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        4.16{" "}
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        4%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        United
                                                                        Arab
                                                                        Emirates
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        3.78
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        4%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Brazil
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        3.77
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        4%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Iran
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        3.01
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        3%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Kuwait
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        2.75
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        3%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Total
                                                                        top 10
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        67.49
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        72%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        World
                                                                        total
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        93.86
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        -
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="views-field views-field-field-faq">
                                                <div class="field-content">
                                                    <div
                                                        id="faqfield_field_faq_node_239"
                                                        class="home-accordion ui-accordion ui-widget ui-helper-reset"
                                                    >
                                                        <h3 class="faqfield-question ui-accordion-header ui-corner-top ui-state-default ui-accordion-icons ui-accordion-header-collapsed ui-corner-all">
                                                            <div
                                                                class="b-bt"
                                                                onClick={() =>
                                                                    showTable(
                                                                        "id-2"
                                                                    )
                                                                }
                                                            >
                                                                What countries
                                                                are the top
                                                                consumers of
                                                                oil?
                                                            </div>
                                                        </h3>
                                                        <div
                                                            class="faqfield-answer ui-accordion-content ui-corner-bottom ui-helper-reset ui-widget-content animate__animated animate__fadeIn"
                                                            id="id-2"
                                                            style={{
                                                                display: "none",
                                                            }}
                                                        >
                                                            <div class="table">
                                                                <div class="tableRow header green">
                                                                    <div class="cell">
                                                                        Country
                                                                    </div>
                                                                    <div class="cell">
                                                                        Million
                                                                        barrels
                                                                        per day
                                                                    </div>
                                                                    <div class="cell">
                                                                        Share of
                                                                        world
                                                                        total
                                                                    </div>
                                                                </div>

                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        United
                                                                        States
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        20.54
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        20%
                                                                    </div>
                                                                </div>

                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        China
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        14.01
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        14%
                                                                    </div>
                                                                </div>

                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        India
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        4.92
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        5%
                                                                    </div>
                                                                </div>

                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Japan
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        3.74
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        4%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Russia
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        3.70
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        4%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Saudi
                                                                        Arabia
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        3.18
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        3%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Brazil
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        3.14
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        3%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        South
                                                                        Korea
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        2.6
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        3%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Canada
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        2.51
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        3%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Germany
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        2.35
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        2%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        Total
                                                                        top 10
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        60.69
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        60%
                                                                    </div>
                                                                </div>
                                                                <div class="tableRow">
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Product"
                                                                    >
                                                                        World
                                                                        total
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Unit Price"
                                                                    >
                                                                        100.23
                                                                    </div>
                                                                    <div
                                                                        class="cell"
                                                                        data-title="Quantity"
                                                                    >
                                                                        -
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="filters" style={{ marginTop: "40px" }}>
                    <div
                        class="b-filter"
                        style={{
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                        }}
                    >
                        <Link to="/digitalTransformation">
                            <img
                                src="/assets/Block/Digitalisation.png"
                                alt="Digital transformation"
                                class="icon"
                                style={{ borderRadius: "50%" }}
                            />
                            <span class="name">
                                Digital Transformation in Oil And Gas
                            </span>
                        </Link>
                    </div>

                    <div
                        class="b-filter"
                        style={{
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                        }}
                    >
                        <Link to="/partners">
                            <img
                                src="/assets/Block/Partners.png"
                                alt="Digital transformation"
                                class="icon"
                                style={{ borderRadius: "50%" }}
                            />
                            <span class="name">Partners</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FAQ;
