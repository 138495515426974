import React from "react";
import jerry from "../../images/images/Bailey_Jerry-removebg-preview1.png";
import ukFlag from "../../images/uk-flag.jpg";
import usaFlag from "../../images/usa-flag.jpg";
import "./style.css";
import { Link } from "react-router-dom";
const Aboutus = () => {
  return (
    <section>
      <div>
        <div className="about-header about-bg wow fadeInUp">
          <img
            src={jerry}
            alt="jerry"
            className="ceo-image  wow zoomIn"
            data-wow-delay="0.3s"
          />
          <h1 className="about-title wow fadeInUp" data-wow-delay="0.3s">
            About Us
          </h1>
        </div>
        <div className="container">
          <div className="about-bottom-card">
            <img
              src={usaFlag}
              alt="usa Flag"
              className="country-flag wow fadeInLeft"
              data-wow-delay="0.3s"
            />
            <div
              className="about-bottom-content wow fadeInRight"
              data-wow-delay="0.4s"
            >
              <h3>The United States and Great Britain</h3>
              <p>
                We are very pleased to announce the fundational relationship we
                have created to “bridge” between the United States and Great
                Britain – a relationship that will be extremely important for
                the long-term success of MMH & ABS Engineering Group{" "}
              </p>
              <Link to="/unitedkingdom">Read More</Link>
            </div>
          </div>
          <div className="about-bottom-card">
            <img
              src={ukFlag}
              alt="uk flag"
              className="country-flag wow fadeInLeft"
              data-wow-delay="0.3s"
            />
            <div
              className="about-bottom-content wow fadeInRight"
              data-wow-delay="0.4s"
            >
              <h3>We are a new company – with lots of history!</h3>
              <p>
                I am pleased to lead the innovative efforts of MMH & ABS
                Engineering Group .I bring a lifelong career of experience in
                the energy industry that uniquely positions me to assist others
                in this arena. I feel that our Company can make a positive
                difference in the lives of people and in the companies with whom
                we work.
              </p>
              <Link to="/ceo">Read More</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
