import React, { useEffect } from "react";
import "./Block.css";
import { Link } from "react-router-dom";
const Block = () => {
  const addActive = (e) => {
    var uiTabs = document.querySelectorAll(".ui-tabs-active");
    uiTabs.forEach((el) =>
      el.getAttribute("class").includes("ui-state-active")
        ? el.classList.remove("ui-state-active")
        : null
    );
    e.target.classList.add("ui-state-active");
    var tabContent = document.querySelectorAll(".tabs-content");
    tabContent.forEach((el) => (el.style.display = "none"));
    let id = e.target.getAttribute("id");
    document.getElementById(`${id}-${id}`).style.display = "block";
  };
  const OurTeam = (event) => {
    event.preventDefault();
    document
      .getElementById("team")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const ObjectiveAndStrategies = (event) => {
    event.preventDefault();
    document
      .getElementById("OBS")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const AboutSection = (event) => {
    event.preventDefault();
    document
      .getElementById("aboutSection")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div
      class="satoripop-builder path-particuliers particuliers-theme  wow fadeInUp "
      data-wow-delay="0.5s"
    >
      <div class="dialog-off-canvas-main-canvas">
        <main>
          <div class="hidden"></div>
          <div id="block-satoripop-tabsparticulierstabs-2">
            <div class="home-tabs container">
              <div
                id="satoripop_tabs-particuliers_tabs"
                class="satoripop_tabs click default ui-tabs ui-corner-all ui-widget ui-widget-content"
              >
                <ul class="ui-tabs-nav ui-corner-all ui-helper-reset ui-helper-clearfix ui-widget-header">
                  <li
                    class="ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active"
                    onClick={addActive}
                    id="1"
                    style={{ cursor: "pointer" }}
                  >
                    About Us
                  </li>
                  {document.querySelector("body").clientWidth < 800 ? (
                    <li
                      class="ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active "
                      onClick={addActive}
                      id="2"
                      style={{ cursor: "pointer" }}
                    >
                      Go To
                      <br />
                      Market Plan
                    </li>
                  ) : (
                    <li
                      class="ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active "
                      onClick={addActive}
                      id="2"
                      style={{ cursor: "pointer" }}
                    >
                      Go To Market Plan
                    </li>
                  )}
                  <li
                    class="ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active "
                    onClick={addActive}
                    id="3"
                    style={{ cursor: "pointer" }}
                  >
                    Management
                  </li>

                  <li
                    class="ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active "
                    onClick={addActive}
                    id="5"
                    style={{ cursor: "pointer" }}
                  >
                    Others
                  </li>
                </ul>
                <div
                  id="1-1"
                  style={{ display: "block" }}
                  class="tabs-content ui-tabs-panel ui-corner-bottom ui-widget-content ui-tabs-panel ui-corner-bottom ui-widget-content"
                >
                  <div class="multiple-content">
                    <div>
                      <Link class="p-links" to="/ceo">
                        <img
                          src="/assets/Block/ceo.png"
                          alt="ceo"
                          class="icon"
                        />
                        <span class="name-icon">CEO’s message 1</span>
                      </Link>
                    </div>
                    <div>
                      <Link class="p-links" to="/unitedkingdom">
                        <img
                          src="/assets/Block/uk_us.png"
                          alt="uk_us"
                          class="icon"
                        />
                        <span class="name-icon">CEO’s message 2</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  id="2-2"
                  class="tabs-content ui-tabs-panel ui-corner-bottom ui-widget-content"
                  style={{ display: "none" }}
                >
                  <div class="multiple-content">
                    <div>
                      <Link class="p-links" to="/objectivesandstrategies">
                        <img
                          src="/assets/Block/244065.png"
                          alt="Objective"
                          class="icon"
                        />

                        <span class="name-icon">Our Objectives</span>
                      </Link>
                    </div>
                    <div>
                      <Link class="p-links" to="/objectivesandstrategies">
                        <img
                          src="/assets/Block/strategy.png"
                          alt="Strategy"
                          class="icon"
                          style={{
                            transform: "scale(1.5)",
                          }}
                        />
                        <span class="name-icon">Our Strategies</span>
                      </Link>
                    </div>
                    <div>
                      <Link class="p-links" to="/sampleproject">
                        <img
                          src="/assets/Block/projects.png"
                          alt="project"
                          class="icon"
                        />
                        <span class="name-icon">Sample Projects</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  id="3-3"
                  class="tabs-content ui-tabs-panel ui-corner-bottom ui-widget-content"
                  style={{ display: "none" }}
                >
                  <div class="multiple-content">
                    <div>
                      <Link class="p-links" to="/management/jerry">
                        <img
                          src={require("../images/Block/ceo.png")}
                          alt="ceo"
                          class="icon"
                        />
                        <span class="name-icon">Our CEO</span>
                      </Link>
                    </div>
                    <div>
                      <Link class="p-links" to="/management/shahid">
                        <img
                          src={require("../images/president.png")}
                          alt="president"
                          class="icon"
                        />
                        <span class="name-icon">Our President</span>
                      </Link>
                    </div>
                    <div>
                      <Link class="p-links" to="/management/ron">
                        <img
                          src={require("../images/director.png")}
                          alt="director"
                          class="icon"
                        />
                        <span class="name-icon">Our Senior Director</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  id="5-5"
                  class="tabs-content ui-tabs-panel ui-corner-bottom ui-widget-content"
                  style={{ display: "none" }}
                >
                  <div class="multiple-content">
                    <Link to="/digitalTransformation">
                      <div>
                        <a class="p-links" href="#">
                          <img
                            src={require("../images/Block/Digitalisation.png")}
                            alt="digitalisation"
                            class="icon"
                            style={{
                              width: "60px",
                              paddingTop: "25px",
                            }}
                          />
                          <span class="name-icon">
                            Digital Transformation of Oil and Gas
                          </span>
                        </a>
                      </div>
                    </Link>
                    <Link to="/FAQ">
                      <div>
                        <a class="p-links" href="#">
                          <img
                            src="assets/Block/FAQ.png"
                            alt="FAQ"
                            class="icon"
                          />
                          <span class="name-icon">FAQ</span>
                        </a>
                      </div>
                    </Link>
                    <Link to="/partners">
                      <div>
                        <a class="p-links" href="#">
                          <img
                            src="/assets/Block/Partners.png"
                            alt="Retraite Complémentaire"
                            class="icon"
                          />
                          <span class="name-icon">Partners</span>
                        </a>
                      </div>
                    </Link>
                  </div>
                </div>
                <div
                  id="4-4"
                  class="tabs-content ui-tabs-panel ui-corner-bottom ui-widget-content"
                  style={{ display: "none" }}
                ></div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Block;
