import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./SimpleProject.css";
import tankfarms from "../images/tankfarms.png";
import sampleProjectCover from "../images/sampleProjectCover.jpg";
import WOW from "wowjs";

const SimpleProject = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <>
      <section class="main-head">
        <div
          class="main-menu-wrapper"
          style={{ position: "absolute", left: "371px" }}
        >
          <ul class="c-menu-main-menu" style={{ zIndex: 999 }}>
            <li>
              <a href="#" class="link">
                <div class="link-before">
                  <img
                    width="54"
                    height="61"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                  ></img>
                </div>
                <Link to="/">
                  <span>Home</span>
                </Link>
              </a>
            </li>
            <li>
              <Link to="/objectivesandstrategies" class="link">
                <div class="link-before">
                  <img
                    width="54"
                    height="61"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                  ></img>
                </div>
                <span style={{ width: "262px" }}>
                  Our Strategies And Objectives
                </span>
              </Link>
              <img
                class="tQfdq"
                style={{
                  top: "-2px",
                  position: "absolute",
                  zIndex: 10,
                  left: "184px",
                  height: "70px",
                }}
                width="54"
                height="61"
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
              ></img>
            </li>
          </ul>
        </div>
        <ul class="nav red-4 navbar-nav">
          <li
            style={{
              position: "absolute",
              right: "50px",
              top: "-34px",
            }}
          >
            <div class="dropdown">
              <span>Arabic</span>

              <div class="dropdown-content">
                <p>English</p>
              </div>
            </div>
          </li>
        </ul>
        <h1>
          <div
            id="imgTitle"
            class="field_title_header"
            style={{
              position: "absolute",
              top: "196px",
              zIndex: 1,
              right: "339px",
              fontSize: "48px",
              fontWeight: 700,
              fontFamily: "Philosopher",
              color: "#fff",
            }}
          >
            SAMPLE PROJECT TARGETS
          </div>
        </h1>
        <div
          className="hero-header"
          style={{
            backgroundImage: `url(${sampleProjectCover})`,
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            backgroundPosition: "center",
          }}
        >
          <div
            class="tQfdq"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "70px",
              padding: "37px 51px",
              position: "absolute",
              backgroundColor: "#fff",
              top: "-38px",
              left: "-72px",
              borderBottomRightRadius: "55px",
              zIndex: 99,
            }}
          >
            <img
              src="/assets/images/mmh-logo.png"
              class="tQfdq"
              alt="logo"
              style={{
                display: "flex",
                alignItems: "center",
                height: "60px",
              }}
            />
          </div>
          <img
            class="tQfdq"
            width="43"
            height="43"
            style={{
              position: "absolute",
              top: "94px",
              zIndex: "3",
              left: "-4px",
            }}
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0M3B4IiB2aWV3Qm94PSIwIDAgNDMgNDMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCA3QDN4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFjY3VlaWwtLS1QYXJ0aWN1bGllcnMtMDEtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0My4wMDAwMDAsIC0yMDcuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00NDMsMjcxIEw0NDMsMjUwLjExOTE4OCBDNDQzLDIyNi4zNzA5NDQgNDYyLjI1MTc1NiwyMDcuMTE5MTg4IDQ4NiwyMDcuMTE5MTg4IEw0ODYsMjA3LjExOTE4OCBMNDg2LDIwNy4xMTkxODggTDQ0MywyMDcuMTkwNDMgTDQ0MywyNzEgWiIgaWQ9IlBhdGgtNyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
          ></img>
          <img
            class="tQfdq"
            style={{
              position: "absolute",
              zIndex: 10,
              top: "-53.7px",
              left: "267px",
              height: "151px",
            }}
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTlweCIgaGVpZ2h0PSIxNDFweCIgdmlld0JveD0iMCAwIDk5IDE0MSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTggKDg0NjYzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoIDcgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNC1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAyLjAwMDAwMCwgMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMwMiwxNDAuMTE5MTg4IEwzMDIuMjAyNDY0LDE0MC4xMTkxODggQzMyMi4yOTkxMzUsMTQwLjExOTE4OCAzNDAuNjY3MDU1LDEyOC43NTI3MjYgMzQ5LjYzNDA3NiwxMTAuNzY3NDkgTDM2OS43MzczNDUsNzAuNDQ2MTgyMyBDMzc1LjY0NzYsNTguNTkxOTMxNiAzODcuNzU0MDgxLDUxLjEwMDE4MTMgNDAxLDUxLjEwMDE4MTMgTDQwMSw1MS4xMDAxODEzIEw0MDEsNTEuMTAwMTgxMyBMNDAxLDAgTDMwMS45OTM1NzEsMCIgaWQ9IlBhdGgtNy1Db3B5Ij48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <img
            src={require("../images/Block/projects.png")}
            alt="projects"
            width="120px"
            height="123px"
            className="wow fadeInUp"
            data-wow-delay="0.3s"
          />
          <h2
            className="Question wow fadeInUp"
            data-wow-delay="0.4s"
            style={{
              fontFamily: "philosopher",
              marginTop: "20px",
              fontSize: "25px",
            }}
          >
            TODAY AND TOMORROW! HOW MAY WE HELP YOU ?
          </h2>
        </div>
      </section>
      <div id="block-particuliers-content">
        <div class="views-element-container">
          <div>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="views-infinite-scroll-content-wrapper clearfix">
                    <article class="SampleProject">
                      <div
                        class="img-container wow fadeInLeft"
                        data-wow-delay="0.3s"
                      >
                        <div class="img">
                          <div class="img-sub-header">
                            <div class="field_sub_header">
                              <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                <div class="field_sub_header_image">
                                  <img
                                    src="./assets/images/ConventionalOilAndGas.jpg"
                                    width="1920"
                                    height="400"
                                    alt="ConventionalOilAndGas "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="coag"
                        class="content"
                        style={{
                          transform: "translateY(-59px)",
                        }}
                      >
                        <h2 class="title wow fadeInRight" data-wow-delay="0.4s">
                          Conventional Oil and Gas
                        </h2>

                        <div
                          class="description wow fadeInRight"
                          data-wow-delay="0.5s"
                        >
                          <p>
                            First efforts are being directed to low cost but
                            efficient conventional fields, to establish company
                            capability and immediate cash flow. Future efforts
                            will seek to acquire increasingly larger fields and
                            offer clients investment opportunities to support
                            their energy portfolios.
                          </p>
                        </div>
                      </div>
                    </article>
                    <article class="SampleProject">
                      <div
                        class="img-container wow fadeInRight"
                        data-wow-delay="0.3s"
                      >
                        <div class="img">
                          <div class="img-sub-header">
                            <div class="field_sub_header">
                              <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                <div class="field_sub_header_image">
                                  <img
                                    src="./assets/images/HeavyOilDevelopment.jpg"
                                    width="1920"
                                    height="400"
                                    alt="Heavy Oil Development"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="hod" class="content">
                        <h2 class="title wow fadeInLeft" data-wow-delay="0.4s">
                          Heavy Oil Development
                        </h2>

                        <div
                          class="description wow fadeInLeft"
                          data-wow-delay="0.5s"
                        >
                          <p>
                            This material is needed by refiners to blend with
                            the abundance of light crudes so the refiners can
                            produce a broad range of products. We have
                            identified groups who are in this space. Future
                            plans would allow us to participate and hold
                            interest in these groups and be able to provide
                            crude to clients.
                          </p>
                        </div>
                      </div>
                    </article>
                    <article class="SampleProject">
                      <div
                        class="img-container wow fadeInLeft"
                        data-wow-delay="0.3s"
                      >
                        <div class="img">
                          <div class="img-sub-header">
                            <div class="field_sub_header">
                              <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                <div class="field_sub_header_image">
                                  <img
                                    src="./assets/images/tertiary.png"
                                    width="1920"
                                    height="400"
                                    alt="tertiary"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="satop"
                        class="content  wow fadeInRight"
                        data-wow-delay="0.4s"
                      >
                        <h2
                          class="title"
                          style={{
                            transform: "translateY(18px)",
                          }}
                        >
                          Secondary and Tertiary Oil Projects
                        </h2>

                        <div class="description">
                          <p>
                            Alternative means are needed to fully exploit oil
                            reservoirs to produce all manner of stranded oil. We
                            have access to several fields in this arena that
                            need our skill in these techniques and we will be
                            using them in our own fields. As customers and
                            clients have similar needs we will deploy our staff
                            and our experience to assist in enhancements.
                          </p>
                        </div>
                      </div>
                    </article>
                    <article class="SampleProject">
                      <div
                        class="img-container  wow fadeInRight"
                        data-wow-delay="0.3s"
                      >
                        <div class="img">
                          <div class="img-sub-header">
                            <div class="field_sub_header">
                              <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                <div class="field_sub_header_image">
                                  <img
                                    src="./assets/images/OilSands.jpg"
                                    width="1920"
                                    height="400"
                                    alt="OilSands "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="osd" class="content">
                        <h2 class="title  wow fadeInLeft" data-wow-delay="0.4s">
                          Oil Sands Development
                        </h2>

                        <div
                          class="description  wow fadeInLeft"
                          data-wow-delay="0.5s"
                        >
                          <p>
                            This is a growth area around the world. We are
                            connected to the two main projects working with
                            current technology and we expect to have joint
                            ventures so we can participate. In the future, we
                            will be able to assist with field developments and
                            production for clients who have access to oil sands
                            reserves.
                          </p>
                        </div>
                      </div>
                    </article>
                    <article class="SampleProject">
                      <div
                        class="img-container  wow fadeInLeft"
                        data-wow-delay="0.3s"
                      >
                        <div class="img">
                          <div class="img-sub-header">
                            <div class="field_sub_header">
                              <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                <div class="field_sub_header_image">
                                  <img
                                    src="./assets/images/pipeline.jpg"
                                    width="1920"
                                    height="400"
                                    typeof="foaf:Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="pipelines"
                        class="content  wow fadeInRight"
                        data-wow-delay="0.3s"
                      >
                        <h2
                          class="title"
                          style={{
                            transform: "translateY(-47px)",
                          }}
                        >
                          Pipelines
                        </h2>

                        <div
                          class="description"
                          style={{
                            transform: "translateY(-53px)",
                          }}
                        >
                          <p>
                            MMH & ABS is not operating any pipelines currently
                            but we have the ability to partner with those who do
                            operate, and for those needing engineering and
                            construction services.
                          </p>
                        </div>
                      </div>
                    </article>
                    <article
                      class="SampleProject  wow fadeInRight"
                      data-wow-delay="0.3s"
                    >
                      <div class="img-container">
                        <div class="img">
                          <div class="img-sub-header">
                            <div class="field_sub_header">
                              <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                <div class="field_sub_header_image">
                                  <img
                                    src="./assets/images/Refineries and Gas Plants.jpg"
                                    width="1920"
                                    height="400"
                                    alt="Refineries and Gas Plants"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="content  wow fadeInLeft"
                        data-wow-delay="0.4s"
                      >
                        <h2
                          class="title"
                          style={{
                            transform: "translateY(-65px)",
                          }}
                        >
                          Refineries and Gas Plants
                        </h2>

                        <div
                          class="description"
                          style={{
                            transform: "translateY(-72px)",
                          }}
                        >
                          <p>
                            Our management has extensive experience in these
                            areas to be operations consultants as well as
                            providing engineering and design.
                          </p>
                        </div>
                      </div>
                    </article>
                    <article class="SampleProject">
                      <div
                        class="img-container  wow fadeInLeft"
                        data-wow-delay="0.3s"
                      >
                        <div class="img">
                          <div class="img-sub-header">
                            <div class="field_sub_header">
                              <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                <div class="field_sub_header_image">
                                  <img
                                    src={tankfarms}
                                    width="1920"
                                    height="400"
                                    alt="tankfarms"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="tat"
                        class="content  wow fadeInRight"
                        data-wow-delay="0.3s"
                      >
                        <h2
                          class="title"
                          style={{
                            transform: "translateY(-50px)",
                          }}
                        >
                          Tankfarms and Terminals
                        </h2>

                        <div
                          class="description"
                          style={{
                            transform: "translateY(-53px)",
                          }}
                        >
                          <p>
                            These infrastructure facilities are add-ons to any
                            oil and gas project and we are prepared to find the
                            resources and handle oversight in any such
                            development.
                          </p>
                        </div>
                      </div>
                    </article>
                    <article class="SampleProject">
                      <div
                        class="img-container  wow fadeInRight"
                        data-wow-delay="0.3s"
                      >
                        <div class="img">
                          <div class="img-sub-header">
                            <div class="field_sub_header">
                              <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                <div class="field_sub_header_image">
                                  <img
                                    src="./assets/images/hydro-power.jpg"
                                    width="1920"
                                    height="400"
                                    alt="hydro-power"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="content  wow fadeInLeft"
                        data-wow-delay="0.4s"
                      >
                        <h2
                          class="title"
                          style={{
                            transform: "translateY(-77px)",
                          }}
                        >
                          Power Generation and Hydroelectric
                        </h2>

                        <div
                          class="description"
                          style={{
                            transform: "translateY(-73px)",
                          }}
                        >
                          <p>
                            We are prepared to expand and participate in these
                            projects via current relationships.
                          </p>
                        </div>
                      </div>
                    </article>
                    <article class="SampleProject">
                      <div
                        class="img-container  wow fadeInLeft"
                        data-wow-delay="0.3s"
                      >
                        <div class="img">
                          <div class="img-sub-header">
                            <div class="field_sub_header">
                              <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                <div class="field_sub_header_image">
                                  <img
                                    src="./assets/images/solar-wind.jpg"
                                    width="1920"
                                    height="400"
                                    alt="solar-wind"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="swgabs"
                        class="content  wow fadeInRight"
                        data-wow-delay="0.4s"
                      >
                        <h2
                          class="title"
                          style={{
                            transform: "translateY(-61px)",
                          }}
                        >
                          Solar, Wind, Geothermal, and Battery Storage
                        </h2>

                        <div
                          class="description"
                          style={{
                            transform: "translateY(-55px)",
                          }}
                        >
                          <p>
                            Current knowledge and associations in operations and
                            technologies will provide us easy entry into these
                            areas as needed.
                          </p>
                        </div>
                      </div>
                    </article>
                    <article class="SampleProject">
                      <div
                        class="img-container  wow fadeInRight"
                        data-wow-delay="0.3s"
                      >
                        <div class="img">
                          <div class="img-sub-header">
                            <div class="field_sub_header">
                              <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                <div class="field_sub_header_image">
                                  <img
                                    src="./assets/images/TruckTerminal.jpg"
                                    width="1920"
                                    height="400"
                                    alt="Fuel Supply and Logistics "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="fsal"
                        class="content  wow fadeInLeft"
                        data-wow-delay="0.4s"
                      >
                        <h2
                          class="title"
                          style={{
                            transform: "translateY(-51px)",
                          }}
                        >
                          Fuel Supply and Logistics
                        </h2>

                        <div
                          class="description"
                          style={{
                            transform: "translateY(-58px)",
                          }}
                        >
                          <p>
                            Our many years in the industry have given us the
                            contacts to facilitate the acquisition of crude oil
                            and a wide range of fuels for our clients along with
                            the supporting logistics.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filters">
        <div class="b-filter wow fadeInUp" data-wow-delay="0.4s">
          <Link to="/objectivesandstrategies">
            <img
              src="/assets/Block/244065.png"
              alt="Team"
              class="icon"
              style={{ borderRadius: "50%" }}
            />
            <span class="name">Objectives & Strategies</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SimpleProject;
