import React, { useEffect } from "react";
import "./Cities.css";
import { Link } from "react-router-dom";
import WOW from "wowjs";

const Cities = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <div>
      <section class="main-head">
        <ul class="nav red-4 navbar-nav">
          <li
            style={{
              position: "absolute",
              right: "50px",
              top: "-34px",
            }}
          >
            <div class="dropdown">
              <span>Arabic</span>

              <div class="dropdown-content">
                <p>English</p>
              </div>
            </div>
          </li>
        </ul>
        <div
          class="main-menu-wrapper"
          style={{ position: "absolute", left: "360px" }}
        >
          <ul class="c-menu-main-menu" style={{ zIndex: 999 }}>
            <li>
              <a href="#" class="link">
                <div class="link-before">
                  <img
                    width="54"
                    height="61"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                  ></img>
                </div>
                <Link to="/">
                  <span>Home</span>
                </Link>
              </a>
            </li>
            <li>
              <Link to="/objectivesandstrategies" class="link">
                <div class="link-before">
                  <img
                    width="54"
                    height="61"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                  ></img>
                </div>
                <span style={{ width: "262px" }}>
                  Our Strategies And Objectives
                </span>
              </Link>
              <img
                style={{
                  top: "-2px",
                  position: "absolute",
                  zIndex: 10,
                  left: "184px",
                  height: "70px",
                }}
                width="54"
                height="61"
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
              ></img>
            </li>
          </ul>
        </div>

        <h1>
          <div
            class="field_title_header"
            id="USGKT"
            style={{
              position: "absolute",
              top: "196px",
              zIndex: 1,
              right: "270px",
              fontSize: "48px",
              fontWeight: 700,
              fontFamily: "Philosopher",
              color: "#fff",
            }}
          >
            The United States and Great Britain
          </div>
        </h1>
        <div
          id="busgb"
          className="hero-header "
          style={{
            backgroundImage: "url(/assets/images/uk.jpg)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            // backgroundPosition: "center",
          }}
        >
          <div
            class="tQfdq"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "70px",
              padding: "37px 51px",
              position: "absolute",
              backgroundColor: "#fff",
              top: "-38px",
              left: "-72px",
              borderBottomRightRadius: "55px",
              zIndex: 99,
            }}
          >
            <img
              src={require("../../images/mmh-logo.png")}
              alt="logo"
              class="tQfdq"
              style={{
                display: "flex",
                alignItems: "center",
                alignItems: "center",
                height: "60px",
              }}
            />
          </div>
          <img
            class="tQfdq"
            width="43"
            height="43"
            style={{
              position: "absolute",
              top: "94px",
              zIndex: "3",
              left: "-4px",
            }}
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0M3B4IiB2aWV3Qm94PSIwIDAgNDMgNDMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCA3QDN4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFjY3VlaWwtLS1QYXJ0aWN1bGllcnMtMDEtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0My4wMDAwMDAsIC0yMDcuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00NDMsMjcxIEw0NDMsMjUwLjExOTE4OCBDNDQzLDIyNi4zNzA5NDQgNDYyLjI1MTc1NiwyMDcuMTE5MTg4IDQ4NiwyMDcuMTE5MTg4IEw0ODYsMjA3LjExOTE4OCBMNDg2LDIwNy4xMTkxODggTDQ0MywyMDcuMTkwNDMgTDQ0MywyNzEgWiIgaWQ9IlBhdGgtNyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
          ></img>
          <img
            class="tQfdq"
            style={{
              position: "absolute",
              zIndex: 10,
              top: "-53.7px",
              left: "257px",
              height: "151px",
            }}
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTlweCIgaGVpZ2h0PSIxNDFweCIgdmlld0JveD0iMCAwIDk5IDE0MSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTggKDg0NjYzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoIDcgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNC1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAyLjAwMDAwMCwgMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMwMiwxNDAuMTE5MTg4IEwzMDIuMjAyNDY0LDE0MC4xMTkxODggQzMyMi4yOTkxMzUsMTQwLjExOTE4OCAzNDAuNjY3MDU1LDEyOC43NTI3MjYgMzQ5LjYzNDA3NiwxMTAuNzY3NDkgTDM2OS43MzczNDUsNzAuNDQ2MTgyMyBDMzc1LjY0NzYsNTguNTkxOTMxNiAzODcuNzU0MDgxLDUxLjEwMDE4MTMgNDAxLDUxLjEwMDE4MTMgTDQwMSw1MS4xMDAxODEzIEw0MDEsNTEuMTAwMTgxMyBMNDAxLDAgTDMwMS45OTM1NzEsMCIgaWQ9IlBhdGgtNy1Db3B5Ij48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
          />
        </div>
      </section>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <img
          src={require("./uk_us.png")}
          alt="uk_Us"
          width="120px"
          height="123px"
          className="logo-img  wow fadeInUp"
          data-wow-delay="0.3s"
        />
      </div>
      <main>
        <div id="block-particuliers-content">
          <div className="views-element-container">
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div
                      data-drupal-views-infinite-scroll-content-wrapper
                      className="views-infinite-scroll-content-wrapper clearfix"
                    >
                      <article
                        className="b-teaser-reversable"
                        style={{
                          marginTop: "50px",
                          marginBottom: "50px",
                        }}
                      ></article>
                      <article
                        className="b-teaser-reversable"
                        style={{
                          marginTop: "50px",
                          marginBottom: "50px",
                        }}
                      >
                        <div
                          className="img-container wow fadeInRight"
                          data-wow-delay="0.3s"
                        >
                          <div className="img">
                            <div className="img-sub-header">
                              <div className="field_sub_header">
                                <div className="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                  <div className="field_sub_header_image">
                                    <img
                                      src="/assets/images/ceo/second.jpg"
                                      width="1920"
                                      height="400"
                                      alt="secondimage"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content">
                          <h2 className="title">
                            <div
                              className="field_titre_a_afficher wow fadeInLeft"
                              data-wow-delay="0.4s"
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              <a href="">New Cooperation , New Era...</a>
                            </div>
                          </h2>

                          <div
                            className="description wow fadeInLeft"
                            data-wow-delay="0.5s"
                          >
                            <div className="field_presentation">
                              <p className="parag">
                                This new cooperative alliance is built upon
                                complementary personnel, expertise,
                                technologies, resources -- and friendships. By
                                bringing together these two energy powerhouses,
                                we will be able to create a global resource that
                                can offer the “best in class” for our valued
                                clients.
                              </p>
                            </div>
                          </div>
                        </div>
                      </article>
                      <article
                        className="b-teaser-reversable"
                        style={{
                          marginTop: "50px",
                          marginBottom: "50px",
                        }}
                      >
                        <div
                          className="img-container wow fadeInLeft"
                          data-wow-delay="0.3s"
                        >
                          <div className="img">
                            <div className="img-sub-header">
                              <div className="field_sub_header">
                                <div className="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                  <div className="field_sub_header_image">
                                    <img
                                      src="/assets/images/ceo/us-uk-flags.jpg"
                                      width="1920"
                                      height="400"
                                      alt="us-uk"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="content wow fadeInRight"
                          data-wow-delay="0.4s"
                        >
                          <h2 className="title">
                            <div
                              className="field_titre_a_afficher"
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              <a href="#">
                                Our commitment for a better future...
                              </a>
                            </div>
                          </h2>

                          <div
                            className="description wow fadeInRight"
                            data-wow-delay="0.5s"
                          >
                            <div className="field_presentation">
                              <p className="parag">
                                Whether we are being asked to consult, manage an
                                energy project, source investment funding,
                                develop projects using traditional or renewable
                                technologies, or simply to provide fuel and
                                logistics, we intend to show the world what a
                                truly dedicated and capable resource can
                                accomplish for our client companies, countries,
                                and citizens.
                              </p>
                            </div>
                          </div>
                        </div>
                      </article>
                      <article
                        className="b-teaser-reversable"
                        style={{
                          marginTop: "50px",
                          marginBottom: "50px",
                        }}
                      >
                        <div
                          className="img-container wow fadeInRight"
                          data-wow-delay="0.3s"
                        >
                          <div className="img">
                            <div className="img-sub-header">
                              <div className="field_sub_header">
                                <div className="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                  <div className="field_sub_header_image">
                                    <img
                                      src="/assets/images/ceo/uk.jpg"
                                      width="1920"
                                      height="400"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="content wow fadeInLeft"
                          data-wow-delay="0.4s"
                        >
                          <h2 className="title">
                            <div
                              className="field_titre_a_afficher"
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              <a href="#">Bringing the World together ...</a>
                            </div>
                          </h2>

                          <div
                            className="description wow fadeInLeft"
                            data-wow-delay="0.5s"
                          >
                            <div className="field_presentation">
                              <p className="parag">
                                This first bridge, of course, will be a model
                                for future relationships with Europe, Africa,
                                the Middle East, and Southeast Asia. Starting in
                                the second half of 2022, we can be reached in
                                our offices in the United States, Great Britain,
                                and the United Emirates.
                              </p>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="filters-footer">
          <div className="b-filter-icons container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="other-title">
                  <div className="field_other_title"></div>
                </h2>
                <h2 className="title">
                  <div className="field_title"></div>
                </h2>
                <div className="description"></div>
                <div className="filters">
                  <div className="b-filter wow fadeInUp" data-wow-delay="0.4s">
                    <Link to="/">
                      <img
                        src="/assets/home_icon.jpg"
                        alt="Mes Comptes"
                        className="icon"
                      />
                      <span className="name">Home</span>
                    </Link>
                  </div>

                  <div className="b-filter wow fadeInUp" data-wow-delay="0.5s">
                    {/* <a href=""> */}
                    <Link to="/ceo">
                      <img
                        src="/assets/Block/ceo.png"
                        alt="Mes Offres Packagées"
                        className="icon"
                      />
                      <span className="name">CEO’s message 2</span>
                    </Link>
                    {/* </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Cities;
