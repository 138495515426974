import React, { useEffect } from "react";
import "./style.css";
import img1 from "../../images/whatWeDo/w-1.png";
import img2 from "../../images/whatWeDo/w-2.png";
import img3 from "../../images/whatWeDo/w-3.png";
import img4 from "../../images/whatWeDo/w-4.png";
import WOW from "wowjs";

const Wwd = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <div className="What-we-Do">
      <h1 className="wwd-title wow fadeInUp" data-wow-delay="1s">
        {" "}
        What We Do ?
      </h1>
      <div className="all-block">
        <div className="block">
          <div>
            <img
              src={img1}
              alt="Project Management"
              className="block-image wow fadeInUp"
              data-wow-delay="0.2s"
            />
            <p className="wow fadeInUp" data-wow-delay="0.3s">
              Consulting & Project Management
            </p>
          </div>
        </div>
        <div className="block">
          <div>
            <img
              src={img2}
              alt=" Investement"
              className="block-image wow fadeInUp"
              data-wow-delay="0.2s"
            />
            <p className="wow fadeInUp" data-wow-delay="0.3s">
              Direct & Third-Party Investement
            </p>
          </div>
        </div>
        <div className="block">
          <div>
            <img
              src={img3}
              alt="renewable energy"
              className="block-image wow fadeInUp"
              data-wow-delay="0.2s"
            />
            <p className="wow fadeInUp" data-wow-delay="0.3s">
              Nonrenewable & renewable energy
            </p>
          </div>
        </div>
        <div className="block">
          <div>
            <img
              src={img4}
              alt="Fuel Supply & Logistics"
              className="block-image wow fadeInUp"
              data-wow-delay="0.2s"
            />
            <p className="wow fadeInUp" data-wow-delay="0.3s">
              Fuel Supply & Logistics
            </p>
          </div>
        </div>
        <div className="block">
          <div className="color-one"></div>
          <div className="color-two"></div>
        </div>
      </div>
    </div>
  );
};

export default Wwd;
