import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./ObjectivesAndStrategies.css";
import objectives2 from "../images/244065.png";
import objectiveImage from "../images/Objectives.jpg";
import WOW from "wowjs";

const ObjectivesAndStrategies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <div className="main-head">
      <section class="main-head">
        <ul class="nav red-4 navbar-nav">
          <li
            style={{
              position: "absolute",
              right: "50px",
              top: "-34px",
            }}
          >
            <div class="dropdown">
              <span>Arabic</span>

              <div class="dropdown-content">
                <p>English</p>
              </div>
            </div>
          </li>
        </ul>
        <div
          class="main-menu-wrapper"
          style={{ position: "absolute", left: "371px" }}
        >
          <ul class="c-menu-main-menu" style={{ zIndex: 999 }}>
            <li>
              <a href="#" class="link">
                <div class="link-before">
                  <img
                    width="54"
                    height="61"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                  ></img>
                </div>
                <Link to="/">
                  <span>Home</span>
                </Link>
              </a>
            </li>
            <li>
              <Link to="/objectivesandstrategies" class="link">
                <div class="link-before">
                  <img
                    width="54"
                    height="61"
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                  ></img>
                </div>
                <span style={{ width: "262px" }}>
                  Our Strategies And Objectives
                </span>
              </Link>
              <img
                style={{
                  top: "-2px",
                  position: "absolute",
                  zIndex: 10,
                  left: "184px",
                  height: "70px",
                }}
                width="54"
                height="61"
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
              ></img>
            </li>
          </ul>
        </div>

        <h1>
          <div
            class="field_title_header"
            style={{
              position: "absolute",
              top: "196px",
              zIndex: 1,
              right: "440px",
              fontSize: "38px",
              fontWeight: 700,
              fontFamily: "Philosopher",
              color: "#fff",
            }}
          >
            Our Objectives & Strategies
          </div>
        </h1>
        <div
          class="hero-header"
          style={{
            backgroundImage: "url(/assets/images/objectivesAndStrategies.jpg)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            backgroundPosition: "center",
          }}
        >
          <div
            class="tQfdq"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "70px",
              padding: "37px 51px",
              position: "absolute",
              backgroundColor: "#fff",
              top: "-38px",
              left: "-72px",
              borderBottomRightRadius: "55px",
              zIndex: 99,
            }}
          >
            <img
              src="/assets/images/mmh-logo.png"
              alt="logo"
              class="tQfdq"
              style={{
                display: "flex",
                alignItems: "center",
                height: "60px",
              }}
            />
          </div>
          <img
            class="tQfdq"
            width="43"
            height="43"
            style={{
              position: "absolute",
              top: "94px",
              zIndex: "3",
              left: "-4px",
            }}
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0M3B4IiB2aWV3Qm94PSIwIDAgNDMgNDMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCA3QDN4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFjY3VlaWwtLS1QYXJ0aWN1bGllcnMtMDEtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0My4wMDAwMDAsIC0yMDcuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00NDMsMjcxIEw0NDMsMjUwLjExOTE4OCBDNDQzLDIyNi4zNzA5NDQgNDYyLjI1MTc1NiwyMDcuMTE5MTg4IDQ4NiwyMDcuMTE5MTg4IEw0ODYsMjA3LjExOTE4OCBMNDg2LDIwNy4xMTkxODggTDQ0MywyMDcuMTkwNDMgTDQ0MywyNzEgWiIgaWQ9IlBhdGgtNyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
          ></img>
          <img
            class="tQfdq"
            style={{
              position: "absolute",
              zIndex: 10,
              top: "-53.7px",
              left: "267px",
              height: "151px",
            }}
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTlweCIgaGVpZ2h0PSIxNDFweCIgdmlld0JveD0iMCAwIDk5IDE0MSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTggKDg0NjYzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoIDcgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNC1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAyLjAwMDAwMCwgMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMwMiwxNDAuMTE5MTg4IEwzMDIuMjAyNDY0LDE0MC4xMTkxODggQzMyMi4yOTkxMzUsMTQwLjExOTE4OCAzNDAuNjY3MDU1LDEyOC43NTI3MjYgMzQ5LjYzNDA3NiwxMTAuNzY3NDkgTDM2OS43MzczNDUsNzAuNDQ2MTgyMyBDMzc1LjY0NzYsNTguNTkxOTMxNiAzODcuNzU0MDgxLDUxLjEwMDE4MTMgNDAxLDUxLjEwMDE4MTMgTDQwMSw1MS4xMDAxODEzIEw0MDEsNTEuMTAwMTgxMyBMNDAxLDAgTDMwMS45OTM1NzEsMCIgaWQ9IlBhdGgtNy1Db3B5Ij48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
          />
        </div>
      </section>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <img
          src={objectives2}
          alt="Ceo"
          width="120px"
          height="123px"
          className="wow fadeInUp"
          data-wow-delay="0.3s"
        />
      </div>
      <div className="container">
        <div id="block-particuliers-content">
          <div class="views-element-container">
            <div>
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div class="views-infinite-scroll-content-wrapper clearfix">
                      <article class="OAS">
                        <div
                          class="img-container wow fadeInLeft"
                          data-wow-delay="0.3s"
                        >
                          <div class="img">
                            <div class="img-sub-header">
                              <div class="field_sub_header">
                                <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                  <div class="field_sub_header_image">
                                    <img
                                      src={objectiveImage}
                                      width="1920"
                                      height="400"
                                      alt="objectives "
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content">
                          <h2
                            class="title wow fadeInRight"
                            data-wow-delay="0.4s"
                          >
                            Our Objectives
                          </h2>

                          <div
                            class="description wow fadeInRight"
                            data-wow-delay="0.5s"
                          >
                            <ul>
                              <li>
                                To consult regarding our clients’ energy
                                requirements and to recommend the most efficient
                                and environmentally-responsible energy solutions
                                while drawing upon the most advanced oil & gas
                                and renewables technologies available.
                              </li>
                              <li>
                                To identify and acquire viable energy investment
                                opportunities for in-house and third-party
                                clients.
                              </li>
                              <li>
                                To support our clients during the engineering,
                                procurement, and construction phases of a
                                project (EPC services), to provide development
                                and ongoing oversight, and to operate energy
                                plants.
                              </li>
                              <li>
                                To source the fuels our clients require and
                                manage the logistics to ensure a reliable
                                supply.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </article>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          marginTop: "20px",
                        }}
                      >
                        <img
                          src={require("../images/Block/strategy.png")}
                          alt="Ceo"
                          className="wow fadeInUp"
                          data-wow-delay="0.3s"
                          width="150px"
                          height="123px"
                        />
                      </div>
                      <div></div>
                      <article class="OAS">
                        <div
                          class="img-container wow fadeInRight"
                          data-wow-delay="0.3s"
                        >
                          <div class="img">
                            <div class="img-sub-header">
                              <div class="field_sub_header">
                                <div class="paragraph paragraph--type--sub-header paragraph--view-mode--accroche">
                                  <div class="field_sub_header_image">
                                    <img
                                      src="./assets/images/strategies.jpg"
                                      width="1920"
                                      height="400"
                                      alt="strategies"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content">
                          <h2
                            class="title wow fadeInLeft"
                            data-wow-delay="0.4s"
                          >
                            Our Strategies
                          </h2>

                          <div
                            class="description wow fadeInLeft"
                            data-wow-delay="0.3s"
                          >
                            <ul id="strategies">
                              <li>
                                Start every assignment with veteran management,
                                planning, and operational personnel with a
                                multi-disciplined team approach.
                              </li>
                              <li>
                                Engage with the most reliable technology
                                partners best equipped to meet any given
                                challenge.
                              </li>
                              <li>
                                Engage top local professionals to ensure all
                                aspects of an assignment are covered . . . from
                                siting and natural resources to infrastructure
                                and politics.
                              </li>
                              <li>
                                Provide real-world field training to the best
                                and brightest talent from local universities.
                              </li>
                              <li>
                                Manage our growth by starting in the United
                                States and Great Britain, and slowly expanding
                                into Africa, the Middle East, and Asia.
                              </li>
                              <li>
                                Always keep our humanitarian concerns in mind in
                                terms of fair treatment, healthy environments,
                                and educational and economic opportunities.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filters">
        <div class="b-filter wow fadeInUp" data-wow-delay="0.4s">
          <Link to="/sampleproject">
            <img
              src="/assets/Block/projects.png"
              alt="Team"
              class="icon"
              style={{ borderRadius: "50%" }}
            />
            <span class="name">Sample Projects</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ObjectivesAndStrategies;
