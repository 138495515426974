import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Link, useHistory } from "react-router-dom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";

import "./Carousel.css";
const Carousel = () => {
  SwiperCore.use([Autoplay]);

  return (
    <>
      <div id="header">
        <div class="container-fluid nopadding">
          <div
            class="navbar red-4 navbar-default yamm "
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div class="navbar-header">
              <button
                type="button"
                data-toggle="collapse"
                data-target="#navbar-collapse-grid"
                class="navbar-toggle two three"
              >
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <Link to="/">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "70px",
                    // marginTop: "10px",
                    padding: "38px 46px",
                    position: "absolute",
                    backgroundColor: "#fff",
                    top: "10px",
                    left: "-72px",
                    borderBottomRightRadius: "55px",
                  }}
                >
                  <img
                    src="/assets/images/mmh-logo.png"
                    alt="logo"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "60px",
                    }}
                  />
                </div>
              </Link>
            </div>

            <div
              id="navbar-collapse-grid"
              class="navbar-collapse collapse pull-right"
            >
              <ul class="nav red-4 navbar-nav">
                <li
                  style={{
                    position: "absolute",
                    right: "50px",
                    top: "10px",
                  }}
                >
                  <div class="dropdown">
                    <span>Arabic</span>

                    <div class="dropdown-content">
                      <p>English</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView={1}
        autoplay={{ delay: 5000 }}
      >
        <img
          class="imthfs"
          width="43"
          height="43"
          style={{
            position: "absolute",
            top: "94px",
            zIndex: "3",
            left: "-4px",
          }}
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSI0M3B4IiB2aWV3Qm94PSIwIDAgNDMgNDMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCA3QDN4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFjY3VlaWwtLS1QYXJ0aWN1bGllcnMtMDEtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ0My4wMDAwMDAsIC0yMDcuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00NDMsMjcxIEw0NDMsMjUwLjExOTE4OCBDNDQzLDIyNi4zNzA5NDQgNDYyLjI1MTc1NiwyMDcuMTE5MTg4IDQ4NiwyMDcuMTE5MTg4IEw0ODYsMjA3LjExOTE4OCBMNDg2LDIwNy4xMTkxODggTDQ0MywyMDcuMTkwNDMgTDQ0MywyNzEgWiIgaWQ9IlBhdGgtNyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
        ></img>
        <img
          class="imthfs"
          style={{
            position: "absolute",
            zIndex: 10,
            top: "-53.7px",
            left: "257px",
            height: "151px",
          }}
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTlweCIgaGVpZ2h0PSIxNDFweCIgdmlld0JveD0iMCAwIDk5IDE0MSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTggKDg0NjYzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoIDcgQ29weTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEtbWlzZS1ham91ci0tIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNC1Db3B5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzAyLjAwMDAwMCwgMC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTMwMiwxNDAuMTE5MTg4IEwzMDIuMjAyNDY0LDE0MC4xMTkxODggQzMyMi4yOTkxMzUsMTQwLjExOTE4OCAzNDAuNjY3MDU1LDEyOC43NTI3MjYgMzQ5LjYzNDA3NiwxMTAuNzY3NDkgTDM2OS43MzczNDUsNzAuNDQ2MTgyMyBDMzc1LjY0NzYsNTguNTkxOTMxNiAzODcuNzU0MDgxLDUxLjEwMDE4MTMgNDAxLDUxLjEwMDE4MTMgTDQwMSw1MS4xMDAxODEzIEw0MDEsNTEuMTAwMTgxMyBMNDAxLDAgTDMwMS45OTM1NzEsMCIgaWQ9IlBhdGgtNy1Db3B5Ij48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
        />
        <img
          class="slogan"
          src="slogan-fr.png"
          style={{
            position: "absolute",
            top: "407px",
            zIndex: 9,
            right: 0,
          }}
        />
        <div
          class="main-menu"
          style={{
            position: "absolute",
            top: "-1px",
            left: "359px",
          }}
        >
          <div class="main-menu-wrapper">
            <ul class="c-menu-main-menu">
              <li>
                <Link to="/" class="link">
                  <div class="link-before">
                    <img
                      width="54"
                      height="61"
                      src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                    ></img>
                  </div>

                  <span>Home</span>
                </Link>
              </li>
              <li>
                <Link to="/objectivesandstrategies" class="link">
                  <div class="link-before">
                    <img
                      width="54"
                      height="61"
                      src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTFweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNTEgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UmVjdGFuZ2xlIENvcHkgNDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xLW1pc2UtYWpvdXItLSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTQtQ29weSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM1MC4wMDAwMDAsIC01MS4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHBhdGggZD0iTTQwMC45NjE4NzcsNTEuMSBMNDAxLDUxLjEgTDQwMSw1MS4xIEw0MDEsMTEwLjEgTDM1MC4wMTQ0NDUsMTEwLjEgTDM2OS41OTg5MTksNzAuNTY0MDc3MyBDMzc1LjUwNDMxNiw1OC42NDI2MjkgMzg3LjY1Nzk0NCw1MS4xIDQwMC45NjE4NzcsNTEuMSBaIiBpZD0iUmVjdGFuZ2xlLUNvcHktNDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                    ></img>
                  </div>
                  <span style={{ width: "262px" }}>
                    Our Strategies And Objectives
                  </span>
                </Link>
                <img
                  style={{
                    top: "-2px",
                    position: "absolute",
                    zIndex: 10,
                    left: "184px",
                    height: "70px",
                  }}
                  width="54"
                  height="61"
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjBweCIgaGVpZ2h0PSI2MHB4IiB2aWV3Qm94PSIwIDAgNjAgNjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCAxNUAzeDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJBcnRib2FyZC1Db3B5LTIiIGZpbGw9IiMyOTY4QjIiPgogICAgICAgICAgICA8cGF0aCBkPSJNNjAsMCBDNDQuNTQ5NDQxNywxLjk3NDYwOTM4IDM0LjU0OTQ0MTcsMTEuOTc0NjA5NCAzMCwzMCBDMjUuNDUwNTU4Myw0OC4wMjUzOTA2IDE1LjQ1MDU1ODMsNTguMDI1MzkwNiAwLDYwIEwwLDAgTDI5Ljg5MDEzNjcsMCBMNjAsMCBaIiBpZD0iUGF0aC0xNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzAuMDAwMDAwLCAzMC4wMDAwMDApIHNjYWxlKC0xLCAtMSkgdHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApICI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
                ></img>
              </li>
            </ul>
          </div>
        </div>
        <SwiperSlide>
          <div class="slide wow fadeIn" data-wow-delay="0.3s">
            <img
              class="sliderImg "
              src="/assets/images/sliders/masterslider/1.jpg"
              alt=""
              style={{ width: "100%", height: "540px" }}
            />

            <h3
              class="animate__animated animate__fadeInRight "
              style={{
                position: "absolute",
                top: "190px",
                left: "169px",
                margin: 0,
                padding: 0,
                lineHeight: "100%",
                letterSpacing: 0,
                color: "#fff",
                fontSize: "50px",
                textAlign: "center",
                textTransform: "uppercase",
                fontFamily: "Philosopher ,sans-serif",
                fontWeight: "bold",
              }}
              id="welcoming"
            >
              Welcome to MMH & ABS
            </h3>

            <h3
              id="egltd"
              class="animate__animated animate__fadeInRight animate__delay-1s"
              style={{
                position: "absolute",
                top: "250px",
                left: "169px",
                margin: 0,
                padding: 0,
                lineHeight: "100%",
                letterSpacing: 0,
                color: "#fff",
                fontSize: "40px",
                textAlign: "center",
                textTransform: "uppercase",
                fontFamily: "Philosopher ,sans-serif",
                fontWeight: "bold",
              }}
            >
              Engineering Group LTD
            </h3>
          </div>
        </SwiperSlide>
        {/* <!-- end slide 1 -->  */}
        {/* <!-- slide 2 --> */}
        <SwiperSlide>
          <div class="slide">
            <img
              class="sliderImg "
              src="/assets/images/sliders/masterslider/2.png"
              alt=""
              style={{ width: "100%", height: "540px" }}
            />
            <h3
              id="cpm"
              class="animate__animated animate__fadeInRight "
              style={{
                position: "absolute",
                top: "190px",
                left: "169px",
                margin: 0,
                padding: 0,
                lineHeight: "100%",
                letterSpacing: 0,
                color: "#fff",
                fontSize: "25px",
                textAlign: "center",
                textTransform: "uppercase",
                fontFamily: "Philosopher ,sans-serif",
                fontWeight: "bold",
              }}
            >
              CONSULTING & PROJECT MANAGEMENT
            </h3>
            <h3
              id="dtpi"
              class="animate__animated animate__fadeInRight "
              style={{
                position: "absolute",
                top: "246px",
                left: "169px",
                margin: 0,
                padding: 0,
                lineHeight: "100%",
                letterSpacing: 0,
                color: "#fff",
                fontSize: "25px",
                textAlign: "center",
                textTransform: "uppercase",
                fontFamily: "Philosopher ,sans-serif",
                fontWeight: "bold",
              }}
            >
              DIRECT & THIRD-PARTY INVESTMENT
            </h3>

            <h3
              id="ogard"
              class="animate__animated animate__fadeInRight animate__delay-1s"
              style={{
                position: "absolute",
                top: "306px",
                left: "169px",
                margin: 0,
                padding: 0,
                lineHeight: "100%",
                letterSpacing: 0,
                color: "#fff",
                fontSize: "25px",
                textAlign: "center",
                textTransform: "uppercase",
                fontFamily: "Philosopher ,sans-serif",
                fontWeight: "bold",
              }}
            >
              OIL & GAS AND RENEWABLES DEVELOPMENT
            </h3>
            <h3
              class="animate__animated animate__fadeInRight animate__delay-1s"
              style={{
                position: "absolute",
                top: "360px",
                left: "169px",
                margin: 0,
                padding: 0,
                lineHeight: "100%",
                letterSpacing: 0,
                color: "#fff",
                fontSize: "25px",
                textAlign: "center",
                textTransform: "uppercase",
                fontFamily: "Philosopher ,sans-serif",
                fontWeight: "bold",
              }}
            >
              FUEL SUPPLY & LOGISTICS
            </h3>
          </div>
        </SwiperSlide>
        {/* <!-- end slide 2 --> */}
        {/* <!-- slide 3 --> */}
        <SwiperSlide>
          <div class="slide">
            <img
              class="sliderImg"
              src="/assets/images/sliders/masterslider/3.jpg"
              alt=""
              style={{ width: "100%", height: "540px" }}
            />
            <h3
              id="usgrbue"
              class="animate__animated animate__fadeInRight "
              style={{
                position: "absolute",
                bottom: "10px",
                left: "13px",
                margin: 0,
                padding: 0,
                lineHeight: "100%",
                letterSpacing: 0,
                color: "#fff",
                fontSize: "30px",
                textAlign: "center",
                textTransform: "uppercase",
                fontFamily: "Philosopher ,sans-serif",
                fontWeight: "bold",
              }}
            >
              UNITED STATES * GREAT BRITAIN * UNITED EMIRATES
            </h3>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Carousel;
