import React from 'react'
import './css/Projects.css'
const Projects = () => {
  return (
    <div className="projects">
      <div className="container-glass">
        <img
          class="img"
          src="https://www.jac-audit-consulting.com/wp-content/uploads/2017/03/OUR-FIRMS_CONSULTING2_600x400_d4542d6cf3989842eeaa56d3c4ee5abc.jpg"
          alt=""
        />
        <p className="text">
          To consult regarding our clients’ energy requirements and to recommend
          the most efficient and environmentally-responsible energy solutions
          while drawing upon the most advanced oil & gas and renewables
          technologies available.
        </p>
      </div>
     
    </div>
  );
}

export default Projects