import { Link } from "react-router-dom";
import "./MoreResponsive.css";
import "./MeetOurTeam.css";
const Team = () => {
  return (
    <section class="team-bg">
      <div class="section-overlay " id="team">
        <div class="container sec-tpadding-3 sec-bpadding-3">
          <div class="row">
            <div class="col-md-12">
              <div class="container">
                <div
                  class="col-xs-12 text-center"
                  style={{ marginTop: "20px" }}
                >
                  <h3
                    class="team-title wow fadeInUp"
                    data-wow-delay="0.3s"
                    style={{ color: "#fff" }}
                  >
                    Meet our Team
                  </h3>
                  {/* <div class="title-line-4 less-margin align-center"></div> */}
                </div>
                <div
                  class="row team-responsive "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-3 card-responsive wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <Link to="/management/jerry">
                      <div class="our-team">
                        <div class="picture">
                          <img
                            class="img-fluid"
                            src="/assets/images/Team/jerry.jpg"
                          />
                        </div>
                        <Link to="/management/jerry">
                          <div class="team-content">
                            <h3 class="name">Dr. Gerald Bailey</h3>
                            <h4 class="title">CEO</h4>
                          </div>
                        </Link>
                        <ul class="social-media">
                          <li>
                            <a
                              href="#"
                              class="fa fa-twitter"
                              aria-hidden="true"
                            ></a>
                          </li>

                          <li>
                            <a
                              href="#"
                              class="fa fa-linkedin"
                              aria-hidden="true"
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-3 card-responsive wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <Link to="/management/shahid">
                      <div class="our-team">
                        <div class="picture">
                          <img
                            class="img-fluid"
                            src="/assets/images/Team/sidi.jpg"
                          />
                        </div>

                        <div class="team-content">
                          <Link to="/management/shahid">
                            <h3 class="name">Sidi Shahid</h3>
                            <h4 class="title">President</h4>
                          </Link>
                        </div>
                        <ul class="social-media">
                          <li>
                            <a
                              href="#"
                              class="fa fa-twitter"
                              aria-hidden="true"
                            ></a>
                          </li>

                          <li>
                            <a
                              href="#"
                              class="fa fa-linkedin"
                              aria-hidden="true"
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </div>
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-3 card-responsive wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <Link to="/management/ron">
                      <div class="our-team">
                        <div class="picture">
                          <img
                            class="img-fluid"
                            src="/assets/images/Team/ron.jpg"
                          />
                        </div>
                        <div class="team-content">
                          <Link to="/management/ron">
                            <h3 class="name">Ron Monat</h3>
                            <h4 class="title">Senior Director</h4>
                          </Link>
                        </div>
                        <ul class="social-media">
                          <li>
                            <a
                              href="#"
                              class="fa fa-twitter"
                              aria-hidden="true"
                            ></a>
                          </li>

                          <li>
                            <a
                              href="#"
                              class="fa fa-linkedin"
                              aria-hidden="true"
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </div>
                  {/* <div class="col-12 col-sm-6 col-md-4 col-lg-3"></div> */}
                </div>
              </div>
              {/* <div class="feature-box71">
                <h4 class="uppercase text-white">100% Responsive</h4>
                <div class="title-line-4 white less-margin"></div>
                <p>
                  Lorem ipsum dolor sit amet, sit et
                  <span class="text-red-5">
                    justo consectetuer adipiscing elit.
                  </span>
                  et sit Suspendisse et justo. Praesent mattis.Lorem ipsum dolor
                  sit amet.
                </p>
                <br />
                <p>
                  Lorem ipsum dolor sit amet, sit et justoconsectetuer
                  adipiscing elit. et sit Suspendisse et justo. Praesent
                  mattis.Lorem ipsum dolor sit amet.
                </p>
                <br />
                <a class="btn btn-red-5" href="#">
                  Read more
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
